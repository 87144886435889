import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import { Label } from "../../atoms/Label/Label";

import "./LabelPicker.scss";


export class LabelPicker extends React.Component {
    constructor({id, values, picked_color, form_key, defaultValue, onclick}) {
        console.log("LabelPicker");
        super();
        this.state = {
            id: id,
            picked_color: picked_color,
            onclick: onclick,
            values: values,
            form_key: form_key,
            defaultValue: defaultValue,
            values_dict: values.map(function(e) {
                if (e == defaultValue){
                    return {name: e, active: true, color: picked_color}
                }
                return {name: e, active: false, color: "white"}
              })
        }
        
    }

    componentDidMount = () => {
    }

    onClickInternal = (e) => {
        console.log("CLICK: ", e.target);
        let values_dict = this.state.values_dict;
        console.log("values_dict: ", values_dict);
        const id = this.state.id;
        const form_key = this.state.form_key;
        const hidden_input = document.getElementById("modal_" + id + "_" + form_key);
        const picked_color = this.state.picked_color;
        let values_dict_new = values_dict.map(function(v) {
            console.log(":v: ", v);
            if (v.name == e.target.innerText){
                if (v.active){
                    // hidden_input.hidden = false;
                    hidden_input.innerHTML = "";
                    // hidden_input.hidden = true;
                    console.log("hidden_input: ", hidden_input.value);
                    return {name: v.name, active: false, color: "white"}
                    
                }
                // hidden_input.hidden = false;
                hidden_input.innerHTML = v.name;
                // hidden_input.hidden = true;
                console.log("hidden_input: ", hidden_input.value);
                return {name: v.name, active: true, color: picked_color}
            }
            return {name: v.name, active: false, color: "white"}
        })
        console.log("values_dict_new: ", values_dict_new);
        
        this.setState(state => ({
            ...state, 
            values_dict: values_dict_new
        }));
    }
    
    render = () => {
        return (
            <div className='label_picker' id={"label_picker_"+this.state.id} key={"label_picker_"+this.state.id}>
                {this.state.values_dict.map((label, index) => 
                    // {label_name == defaultValue 
                    //     ? <b onClick={onclick}>{label_name}</b>
                    //     : <p onClick={onclick}>{label_name}</p>
                    // }
                    <>
                    {
                        label.active 
                        ? <Label key={"label_picker--item--"+index} color={label.color} onClick={this.onClickInternal} text={label.name}/>
                        // <p key={"label_picker--item--"+index} className='label_picker--item--selected' onClick={this.onClickInternal}>{label.name}</p>
                        : <Label key={"label_picker--item--"+index} color={label.color} onClick={this.onClickInternal} text={label.name}/>
                        // <p key={"label_picker--item--"+index} className='label_picker--item' onClick={this.onClickInternal}>{label.name}</p>
                    }
                    </>
                    
                    
                )}
                <input key={this.state.form_key} id={"modal_" + this.state.id + "_" + this.state.form_key} type={"text"} hidden={true} defaultValue={this.state.defaultValue} ></input>
            </div>
        )
    }
}
