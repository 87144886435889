import React from 'react';
import PropTypes from 'prop-types';

import { CardButton } from '../../atoms/Button/CardButton';
import { CloseButton } from '../../atoms/CloseButton/CloseButton';
import { CardMenu } from '../../molecules/CardMenu/CardMenu';
import { CardImage } from '../../molecules/CardImage/CardImage';

import './Sharing.scss';


export const SharingModal = ({id, modal_title, fields, onSubmit, closeFunc, submitButtonName="Скопировать ссылку"}) => {

  const onChange = (e) => {
    console.log(e);
  }
  return(
    <div className="modal" key={id.toString()}>
      <form>
        <div className="modal--elements">
          <div className="modal--elements--header">
            <h3>{modal_title}</h3>
            <CloseButton onClick={closeFunc}/>
          </div>
          <div className='fixed_height_container'>
            <div className='scrollable'>
              {fields.map((field, index) => 
                  // <Card key={index.toString()} id={index.toString()} {...card_data}  />
                  <div key={index.toString()} className="modal--element">
                    <b>{field.name}</b>
                    {field.description ? <div className="hint"><p>{field.description}</p></div> : <br/>}
                    <input key={field.form_key} id={"modal_" + id + "_" + field.form_key} type={field.input_type} defaultValue={field.value} readOnly={true}></input>
                  </div>
              )}
              <p><u>Персональная ссылка позволяет добавить одного человека.</u></p>
              
              <p>Создавать персональные ссылки можно в неограниченном количестве.</p>
              
            </div>
            
          </div>
          
        </div>
      <CardButton text={submitButtonName} onClick={onSubmit}/>
      </form>
    </div>
);
}

export const SharingAcceptModal = ({id, modal_title, fields, onSubmit, closeFunc, submitButtonName="Скопировать ссылку"}) => {

  const onChange = (e) => {
    console.log(e);
  }

  console.log("fields.error: ", fields.error);
  return(
    <div className="modal modal-shadow" key={id.toString()}>
      <form>
        <div className="modal--elements">
          <div className="modal--elements--header">
            <h3>{modal_title}</h3>
            <CloseButton onClick={closeFunc}/>
          </div>
          <br></br>
          <div className='fixed_height_container'>
            <div className='scrollable'>
              {(fields.title != undefined)
                ?
                <>
                  <p>Подтвердите добавление в проект:</p>
                  <b>{fields.title}</b>
                  <p><u>Это персональная ссылка, только вы сможете подтвердить по ней доступ.</u></p>
                </>
                : <></>
              }
              {(fields.ok != undefined)
                ?
                <>
                  <b>{fields.ok}</b>
                </>
                : <></>
              }
              {(fields.error != undefined)
                ?
                <>
                  <b>{fields.error}</b>
                </>
                : <></>
              }
              
            </div>
            
          </div>
          
        </div>
      <CardButton text={submitButtonName} onClick={onSubmit}/>
      </form>
    </div>
);
}

// TODO: !!!!!!! Close modal button

SharingModal.propTypes = {
  id: PropTypes.string.isRequired,
  modal_title: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func,
};
