import React from 'react';
import PropTypes from 'prop-types';


// import * as placeholder_scss from "./Placeholder.scss";
import "./Placeholder.scss";


export const Placeholder = (props) => {


    // let className= "lud_placeholder"
    // className = props.rounded ? className + " lud_placeholder--styling__rounded" : className;
    // if ((props.color !== null) || (props.color !== undefined)){
    //     className += " lud_placeholder--color__"+props.color;
    // }

    let radius = props.rounded ? "3px" : "0px";

    radius = props.radius ? props.radius : radius;

    // console.log("height: ", props.height);
    const width = props.width;
    const height = props.height;

    const style = {
        width: width, 
        height: height,
        margin: props.margin,
        backgroundColor: "#d3d3d3",
        borderRadius: radius,
        animation: "placeholder-glow 2s ease-in-out infinite"
    };
    
    // const ChildComponent = props.child ? props.child : <></>;
    // className={className}
    return (
        <div style={style}>
            {/* <ChildComponent /> */}
        </div>
    )
}

Placeholder.propTypes = {
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    margin: PropTypes.string,
    rounded: PropTypes.bool,
    radius: PropTypes.string,
    color: PropTypes.oneOf([null, "primary", "secondary", "ternary"])
};


Placeholder.defaultProps = {
    rounded: true,
    color: null
};

