import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./DateTimeLabel.scss";


// import {Placeholder} from '../Placeholder/Placeholder';

export const DateTimeLabel = ({id, key_prop, data, color}) => {
    let d = new Date(data);
    let classname = "datetimelabel datetimelabel--white";
    const options = { 
        weekday: 'long',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
    };

    return (
        <div key={key_prop} id={id} className={classname}>
            <p className={"datetimelabel_p"}>{d.toLocaleString('ru-RU', options)}</p>
        </div>
    )
}


// Button.propTypes = {
//     // className: PropTypes.string,
//     color: PropTypes.oneOf(['default', 'primary', 'secondary', 'ternary']),
//     // size: PropTypes.oneOf(['small', 'medium', 'large']),
//     fixed_width: PropTypes.string,
//     fixed_height: PropTypes.string,
//     child: PropTypes.node,
//     onClick: PropTypes.func,
// };


// Button.defaultProps = {
//     onClick: undefined,
// };

