import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardAdd } from '../../organisms/Card/Card';

import './ExpertList.scss';
import grenki from "../../static/images/grenki.jpeg";

import { fetchModule } from '../../fetch'


export class ExpertList extends React.Component {
        constructor() {
            super();
            this.state = {
                projects_metadata: {},
                projects_data: [
                    {
                        color: "violet",
                        title: "Портреты клиентов",
                        description: "Описание клиентов эксперта",
                        prefix_url: "/portraits/",
                    },
                    {
                        color: "gray", //"red",
                        title: "Опыт эксперта",
                        description: "Образование, сильные стороны. (Раздел в разработке)",
                        prefix_url: "", //"/auditory/"
                    },
                    {
                        color: "gray", //"orange",
                        title: "Кейсы",
                        description: "Описание кейсов, которые были у эксперта. (Раздел в разработке)",
                        prefix_url: "",
                    },
                    // 
                    {
                        color: "gray",
                        title: "Миссия эксперта",
                        description: "Почему он делает то, что он делает. (Раздел в разработке)",
                        prefix_url: "",
                    }
                ],
                project_id: ""
            }
        }
    
        componentDidMount = () => {
            const project_id = window.location.pathname.replace("/project/", "").replace("/expert/", "");
            console.log("_____project_id: ", project_id);
            this.getProjects(project_id);
            this.setState(state => ({
                ...state, 
                project_id: project_id
            })); 
        }
    
        getProjects = async (project_id) => {
            try {
              const response = await fetchModule.doGet({path: '/project/'+project_id});
              if ((response.status >= 200) && (response.status < 400)) {
                  let json = await response.json();
                  this.setState(state => ({
                    ...state, 
                    projects_metadata: json
                  }));  
                  return json;
              }
              else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                return json;
              } 
              else {
                  throw response.status; 
              }
            } catch (error) {
                throw error;
            }
        }
    
        render () {
            return(
                <div className="project">
                    <div className="project--text">
                        <h1>{this.state.projects_metadata.title}</h1>
                    </div>
                    <div className="project--body">
                        {this.state.projects_data.map((card_data, index) => 
                            <Card
                                card_type={"project"}
                                has_sub_menu={false}
                                has_id_in_url={false}
                                parent_prefix_url={"/project/" + this.state.project_id + "/expert"}
                                card_prefix_url={card_data.prefix_url}
                                key={index.toString()}
                                id={index.toString()}
                                {...card_data}
                            />
                        )}
                    </div>
                    
                </div>
            );
        }
}
