import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { Card, CardAdd } from '../../organisms/Card/Card';

import { Modal } from '../../organisms/Modal/Modal';
import { SharingModal } from '../../organisms/Sharing/Sharing'; 

import './ProjectsList.scss';

import { fetchModule } from '../../fetch'
import { metric } from '../../metric'




// export const ProjectsList = () => {

export class ProjectsList extends React.Component {
    constructor() {
        super();
        this.state = {
            show_archived: false,
            projects_data: [],
            projects_data_loaded: false,
        }
        this.PROJECT_CREATE_MODAL_ID = "project_create_modal_id"
    }

    componentDidMount = () => {
        this.getProjects();
        metric.page_visit("project_list");
    }

    modalPlacement = (element) => {
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = "projectslist projectslist--blurred"
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );

        modal_element.render(element);
    }

    cardEditModal = (card_id, title, description, instagram_url) => {
        console.log("cardEditModal___card_id: ", card_id, title, description, instagram_url);
        
        const project_create_modal_fields = [
            {
                "form_key": "id",
                "name": "",
                "input_type": "hidden",
                "value": card_id
            },
            {
                "form_key": "title",
                "name": "Название проекта / имя эксперта",
                // "description": "какое-то описание",
                "input_type": "text",
                "value": title
            },
            {
                "form_key": "description",
                "name": "Краткое описание запуска",
                "input_type": "textarea",
                "value": description
            },
            {
                "form_key": "instagram_url",
                "name": "Ссылка на Instagram эксперта",
                "description": "Введите instagram через @, например: @producer.tool",
                "input_type": "url",
                "value": instagram_url
            }
        ]
        const project_create_modal = <Modal 
            id={this.PROJECT_CREATE_MODAL_ID} 
            modal_title={"Редактирование: " + title}
            fields={project_create_modal_fields}
            closeFunc={this.closeProjectCreationModal}
            onSubmit={this.onSubmitUpdateProject}
            submitButtonName={"Сохранить"}
        />
        this.modalPlacement(project_create_modal);
    }

    cardShareModal = async (card_id, title) => {
        console.log("cardShareModal___card_id: ", card_id, title);

        // получение ссылки/ключа/токена для поделиться;
        let sharing_token = "";
        let response = {}
        
        try {
            response = await fetchModule.doPost({path: '/share/create?project_id=' + card_id});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
        
                const root_url = window.location.origin;
                const sharing_link = root_url + "/share/" + json["sharing_token"];
                console.log("sharing_link: ", sharing_link);
                
                const project_share_modal_fields = [
                    {
                        "form_key": "id",
                        "name": "",
                        "input_type": "hidden",
                        "value": card_id,
                        "readobly": false
                    },
                    {
                        "form_key": "sharing_link",
                        "name": "Персональная ссылка",
                        // "description": "какое-то описание",
                        "input_type": "text",
                        "value": sharing_link,
                        "readobly": true
                    }
                ]
                const project_share_modal = <SharingModal 
                    id={this.PROJECT_CREATE_MODAL_ID} 
                    modal_title={"Поделиться доступом к проекту: " + title}
                    fields={project_share_modal_fields}
                    closeFunc={this.closeProjectCreationModal}
                    onSubmit={this.onSubmitSharingCopy}
                    submitButtonName={"Скопировать ссылку"}
                />
                this.modalPlacement(project_share_modal);
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
        
        // отрисовка элемента:
        // ссылка, чтобы можно было скопировать, кнопка поделиться
    }

    archiveCard = async (project_id, current_archive_status) => {
        console.log("Archiving project");
        console.log("current_archive_status: ", current_archive_status);
        const project_data = {}
        project_data['archived'] = !current_archive_status;
        console.log("project_id: ", project_id);
        console.log("project_data: ", project_data);

        try {
            const response = await fetchModule.doPut({path: '/project/'+project_id + "/", body: project_data});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                  ...state, 
                  projects_data: json["projects"]
                }));
                this.closeProjectCreationModal(null);
                this.closeCardSettingsModal(project_id);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                this.closeCardSettingsModal(project_id);
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
    }

    onSubmitSharingCopy = async (e) => {
        console.log(e)
        let copyGfGText = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + "sharing_link");
        
        /* Select the text field */
        copyGfGText.select();
        
        /* Copy the text inside the text field */
        document.execCommand("copy");

        let modal_card_button = document.getElementsByClassName("card_button card_button--primary");
        console.log("modal_card_button: ", modal_card_button[0]);
        modal_card_button[0].className = "card_button card_button--green";

        // card_button card_button--green
        
    }

    getProjects = async () => {
        try {
          const response = await fetchModule.doGet({path: '/project/'});
          if ((response.status >= 200) && (response.status < 400)) {
              let json = await response.json();
              this.setState(state => ({
                ...state, 
                projects_data_loaded: true,
                projects_data: json["projects"]
              }));  
              return json;
          }
          else if ((response.status >= 400) && (response.status < 404)) {
            let json = await response.json();
            return json;
          } 
          else {
              throw response.status; 
          }
        } catch (error) {
            throw error;
        }
    }

    closeCardSettingsModal = (card_id) => {
        const element = document.getElementById(card_id + "__card_menu_modal")
        element.hidden = "True";
    }

    closeProjectCreationModal = (e) => {
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = ""
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        modal_element.render(<></>);
    }

    openProjectCreationModal = (e) => {
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = "projectslist projectslist--blurred"
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        const project_create_modal_fields = [
            {
            "form_key": "title",
            "name": "Название проекта / имя эксперта",
            "input_type": "text"
            },
            {
            "form_key": "description",
            "name": "Краткое описание запуска",
            "input_type": "textarea"
            },
            {
            "form_key": "instagram_url",
            "name": "Ссылка на Instagram эксперта",
            "description": "Введите instagram через @, например: @producer.tool",
            "input_type": "url"
            }
        ]
        const project_create_modal = <Modal 
            id={this.PROJECT_CREATE_MODAL_ID} 
            modal_title={"Создание проекта"}
            fields={project_create_modal_fields}
            closeFunc={this.closeProjectCreationModal}
            onSubmit={this.onSubmitNewProject}
        />
        modal_element.render(project_create_modal);
    }

    onSubmitNewProject = async (e) => {
        console.log(e)
        const form_keys_array = ["title", "description", "instagram_url"]
        // for key_name in form_keys_array
        const project_data = {}
        form_keys_array.map((key_name, index) => {
                console.log("_________: ", this.PROJECT_CREATE_MODAL_ID + "_" + key_name);
                const element = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + key_name)
                console.log(key_name, ": ", element.value);
                project_data[key_name] = element.value;
            }
        )
        console.log("project_data: ", project_data);

        try {
            const response = await fetchModule.doPost({path: '/project/', body: project_data});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                  ...state, 
                  projects_data: json["projects"]
                }));
                this.closeProjectCreationModal(null);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
    }

    onSubmitUpdateProject = async (e) => {
        console.log(e)
        const form_keys_array = ["title", "description", "instagram_url"]
        // for key_name in form_keys_array
        const project_data = {}
        form_keys_array.map((key_name, index) => {
                console.log("_________: ", this.PROJECT_CREATE_MODAL_ID + "_" + key_name);
                const element = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + key_name)
                console.log(key_name, ": ", element.value);
                project_data[key_name] = element.value;
            }
        )
        const element_id = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + "id")
        const project_id = element_id.value;
        console.log("project_id: ", project_id);
        console.log("project_data: ", project_data);

        try {
            const response = await fetchModule.doPut({path: '/project/'+project_id + "/", body: project_data});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                  ...state, 
                  projects_data: json["projects"]
                }));
                this.closeProjectCreationModal(null);
                this.closeCardSettingsModal(project_id);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                this.closeCardSettingsModal(project_id);
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
        
    }

    showArchived = (e) => {
        let show_archived = !this.state.show_archived;
        this.setState(state => ({
            ...state, 
            show_archived: show_archived
        }));
    }

    render () {
        console.log("this.state.show_archived: ", this.state.show_archived);
        return(
            <div className="projectslist">
                <div id="card_add_modal_placement" className="projectslist--modal"></div>
                <div id="projectslist">
                    <div className="projectslist--text">
                        <h1>Все проекты</h1>
                    </div>
                    <div className="projectslist--text">
                        {this.state.show_archived 
                        ? <p onClick={this.showArchived}>Скрыть архивированные</p>
                        : <p onClick={this.showArchived}>Показать архивированные</p>
                        }
                    </div>                    
                    <div className="projectslist--body">
                        {this.state.projects_data.map((card_data, index) => 
                            {
                                if (card_data.archived) {
                                    card_data.color = "gray";
                                }
                                if ((this.state.show_archived && card_data.archived) || (!card_data.archived)){
                                    return <Card 
                                        card_type={"project"}
                                        parent_prefix_url={"/"}
                                        card_prefix_url={"/project/"}
                                        key={index.toString()}
                                        cardEditModal={this.cardEditModal}
                                        has_share_in_sub_menu={true}
                                        cardShareModal={this.cardShareModal}
                                        has_archive_in_sub_menu={true}
                                        archiveCard={this.archiveCard}
                                        {...card_data}
                                    />
                                }
                                
                            }
                            
                        )}
                        {
                            ((this.state.projects_data.length == 0) && (this.state.projects_data_loaded == true))
                            ? <div>
                                <h2>Проекты ещё не созданы</h2>
                                <p>Проект – это рабочее пространство, где вы можете вести все, что связано с продюсированием вашего эксперта</p>
                                <h3>Нажмите + чтобы создать проект</h3>
                                <CardAdd key={"add_card_key"} id={"add_card_id"} onClick={this.openProjectCreationModal}/>
                              </div>
                            : <CardAdd key={"add_card_key"} id={"add_card_id"} onClick={this.openProjectCreationModal}/>
                        }
                        
                    </div>
                </div>
            </div>
        );
    }
}
