import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./CardButton.scss";


// import {Placeholder} from '../Placeholder/Placeholder';

export const CardButton = ({text, sub_text, disabled, error, green, button_color, high, bold_text, onClick}) => {        
    let classname = disabled == true ? "card_button card_button--disabled" : "card_button card_button--primary"
    classname = error == true ? "card_button card_button--error" : "card_button card_button--primary"
    classname = green == true ? "card_button card_button--green" : "card_button card_button--primary"
    classname = button_color ? "card_button card_button--"+button_color : "card_button card_button--primary"
    if (high) {
        classname += " card_button--high";
    }
    console.log("button_color: ", button_color, "text: ", text);
    console.log("classname: ", classname, "text: ", text);
    console.log("green: ", green, "text: ", text);
    return (
        <div className={classname} onClick={onClick}>
            {bold_text ? <h3>{text}</h3> : <p>{text}</p>}
            {sub_text ? <sub>{sub_text}</sub> : ""}
        </div>
    )
}


// Button.propTypes = {
//     // className: PropTypes.string,
//     color: PropTypes.oneOf(['default', 'primary', 'secondary', 'ternary']),
//     // size: PropTypes.oneOf(['small', 'medium', 'large']),
//     fixed_width: PropTypes.string,
//     fixed_height: PropTypes.string,
//     child: PropTypes.node,
//     onClick: PropTypes.func,
// };


// Button.defaultProps = {
//     onClick: undefined,
// };

