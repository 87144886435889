import React from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom'

import './Header.scss';

import logo from "../../assets/icons/logo.svg";
// import pari_pub_logo from "../../assets/icons/pari_pub_logo.jpeg";
import back_icon from "../../assets/icons/back_icon.svg";
import { metric } from '../../metric'


const onClickBack = () => {
  metric.click("back_button");
  if (window.history.state.idx > 0){
    window.history.back();
  }
}


const onClickLogin = () => {
  metric.click("open_login_button");
  window.open("/login/", "_self");
}

const toForm = () => {
  metric.click("know_first_header_button");
  window.location.hash = "#know_first";
}


export const Header = ({logged_in}) => {

  // let navigate = useNavigate();

  const onClickLogo = () => {
    // navigate("/");
    window.open("/", "_self");
  }
  

  // клавиша назад не всегда появляется
  // let back_button = "";
  // if (logged_in) {
  //   console.log("window.history.state.idx: ", window.history.state.idx);
  //   if (window.history.state.idx > 0) {
  //     back_button = <div className="sub_menu_button" onClick={onClickBack}>
  //                     <img src={back_icon}/>
  //                   </div>
  //   }
  // }

  let back_button = ""
  if (logged_in){
    back_button = <div className="sub_menu_button" onClick={onClickBack}>
                    <img src={back_icon}/>
                    назад
                  </div>
  } else {
    back_button = <div className="sub_menu_button" onClick={onClickLogin}>
                      <b>Войти</b>
                    </div>
  }
  
  let header_classname = logged_in ? "header" : "header--landing"

  return (
    <div className={header_classname}>
      <div className="myfood_tech_logo" onClick={onClickLogo}>
        <img src={logo}/>
        producer tools
      </div>
      {back_button}
    </div>
  )
};

