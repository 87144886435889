import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { Card, CardAdd } from '../../organisms/Card/Card';

import { Modal } from '../../organisms/Modal/Modal';

import './Stories.scss';

import { fetchModule } from '../../fetch'

import stories_forward from "../../assets/icons/stories_forward.svg";
import stories_backward from "../../assets/icons/stories_backward.svg";


export class Stories extends React.Component {
    constructor({stories = [], stub=false}) {
        console.log("Stories");
        super();
        this.stub = stub;
        console.log("stub constr: ", this.stub);
        this.state = {
            title: "Истории",
            current_story_index: 0,
            project_id: -1,
            content_plan_id: -1,
            stories: stories,
            stories_loaded: false
        }
        this.PROJECT_CREATE_MODAL_ID = "project_create_modal_id"
    }

    componentDidMount = () => {
        this.getContentPlans();
    }

    cardEditModal = (content_plan_id, title, essence, example, materials) => {
        console.log("cardEditModal___card_id: ", content_plan_id, title, essence, example, materials);
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = "projectslist projectslist--blurred"
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        const project_create_modal_fields = [
            {
                "form_key": "id",
                "name": "",
                "input_type": "hidden",
                "value": content_plan_id
            },
            {
                "form_key": "title",
                "name": "Название истории",
                "input_type": "text",
                "value": title
            },
            {
                "form_key": "essence",
                "name": "Суть истории",
                "description": "На что нужно “надавить” в рассказе",
                "input_type": "textarea",
                "value": essence
            },
            {
                "form_key": "example",
                "name": "Пример текста (смысла)",
                "description": "Вариант рассказа от продюсера",
                "input_type": "textarea",
                "value": example
            },
            {
                "form_key": "materials",
                "name": "Материалы",
                "description": "Формат истории, например: фото, видео или запись вживую",
                "input_type": "text",
                "value": materials
            }
        ]
        const project_create_modal = <Modal 
            id={this.PROJECT_CREATE_MODAL_ID} 
            modal_title={"Редактирование: " + title}
            fields={project_create_modal_fields}
            closeFunc={this.closeProjectCreationModal}
            onSubmit={this.onSubmitUpdateProject}
            submitButtonName={"Сохранить"}
        />
        modal_element.render(project_create_modal);
    }

    getContentPlans = async () => {
        console.log("stub get: ", this.stub);
        if (this.stub){
            return {}
        }
        const project_id = window.location.pathname.split("/content_plan")[0].replace('project', '').replace(/\//g, '');
        console.log("project_id: ", project_id);
        const content_plan_id = window.location.pathname.split("/content_plan")[1].replace('stories', '').replace(/\//g, '');
        console.log("content_plan_id: ", content_plan_id);
        this.setState(state => ({
            ...state, 
            stories_loaded: true,
            project_id: project_id,
            content_plan_id: content_plan_id,
        }));
        try {
          const response = await fetchModule.doGet({path: '/project/' + project_id + "/content_plan/" + content_plan_id + "/stories/"});
          if ((response.status >= 200) && (response.status < 400)) {
              let json = await response.json();
              console.log("stories: ", json["stories"]);

              if (json["stories"].length == 0) {
                const element = document.getElementById("stories_forward_button");
                element.hidden = 'True';
              }
              this.setState(state => ({
                ...state, 
                stories: json["stories"]
              }));  
              return json;
          }
          else if ((response.status >= 400) && (response.status < 404)) {
            let json = await response.json();
            return json;
          } 
          else {
              throw response.status; 
          }
        } catch (error) {
            throw error;
        }
    }

    closeCardSettingsModal = (card_id) => {
        const element = document.getElementById(card_id + "__card_menu_modal")
        element.hidden = "True";
    }

    closeProjectCreationModal = (e) => {
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = ""
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        modal_element.render(<></>);
    }

    openProjectCreationModal = (e) => {
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = "projectslist projectslist--blurred"
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        const project_create_modal_fields = [
            {
                "form_key": "title",
                "name": "Название истории",
                "input_type": "text"
            },
            {
                "form_key": "essence",
                "name": "Суть истории",
                "description": "На что нужно “надавить” в рассказе",
                "input_type": "textarea"
            },
            {
                "form_key": "example",
                "name": "Пример текста (смысла)",
                "description": "Вариант рассказа от продюсера",
                "input_type": "textarea"
            },
            {
                "form_key": "materials",
                "name": "Материалы",
                "description": "Формат истории, например: фото, видео или запись вживую",
                "input_type": "text"
            }
        ]
        const project_create_modal = <Modal 
            id={this.PROJECT_CREATE_MODAL_ID} 
            modal_title={"Добавить историю"}
            fields={project_create_modal_fields}
            closeFunc={this.closeProjectCreationModal}
            onSubmit={this.onSubmitNewProject}
            submitButtonName={"Добавить историю"}
        />
        modal_element.render(project_create_modal);
    }

    onSubmitNewProject = async (e) => {
        console.log(e)
        const project_id = this.state.project_id;
        const content_plan_id = this.state.content_plan_id;
        const form_keys_array = ["title", "essence", "example", "materials"]
        // for key_name in form_keys_array
        const stories_data = {}
        form_keys_array.map((key_name, index) => {
                console.log("_________: ", this.PROJECT_CREATE_MODAL_ID + "_" + key_name);
                const element = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + key_name)
                console.log(key_name, ": ", element.value);
                stories_data[key_name] = element.value;
            }
        )
        console.log("stories_data: ", stories_data);

        try {
            const response = await fetchModule.doPost({path: '/project/' + project_id + "/content_plan/" + content_plan_id + "/stories/", body: stories_data});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                const element = document.getElementById("stories_forward_button");
                element.hidden = '';
                this.setState(state => ({
                  ...state, 
                  stories: json["stories"]
                }));
                this.closeProjectCreationModal(null);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
    }

    onSubmitUpdateProject = async (e) => {
        console.log(e)
        const project_id = this.state.project_id;
        const content_plan_id = this.state.content_plan_id;
        
        const form_keys_array = ["title", "essence", "example", "materials"]
        // for key_name in form_keys_array
        const stories_data = {}
        form_keys_array.map((key_name, index) => {
                console.log("_________: ", this.PROJECT_CREATE_MODAL_ID + "_" + key_name);
                const element = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + key_name)
                console.log(key_name, ": ", element.value);
                stories_data[key_name] = element.value;
            }
        )
        const element_id = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + "id")
        const story_id = element_id.value;
        console.log("story_id: ", story_id);
        console.log("stories_data: ", stories_data);

        try {
            const response = await fetchModule.doPut({path: '/project/' + project_id + "/content_plan/" + content_plan_id + "/stories/" + story_id, body: stories_data});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                  ...state, 
                  stories: json["stories"]
                }));
                this.closeProjectCreationModal(null);
                this.closeCardSettingsModal(story_id);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                this.closeCardSettingsModal(story_id);
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
        
    }

    onButtonPublicatedFlagClick = async (story_id) => {
        const project_id = this.state.project_id;
        const content_plan_id = this.state.content_plan_id;
        try {
            const response = await fetchModule.doPut({path: '/project/' + project_id + "/content_plan/" + content_plan_id + "/stories/" + story_id + "/publicated/"});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                  ...state, 
                  stories: json["stories"]
                }));
                this.closeProjectCreationModal(null);
                this.closeCardSettingsModal(story_id);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                this.closeCardSettingsModal(story_id);
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }

        // let stories = this.state.stories
        // stories[story_id].publicated = !stories[story_id].publicated;
        // this.setState(state => ({
        //     ...state, 
        //     stories: stories
        // }));
    }

    onForwardClick = (e) => {
        let current_story_index = this.state.current_story_index;
        current_story_index += 1;

        if (current_story_index > 0) {
            const element = document.getElementById("stories_backward_button");
            element.hidden = '';
        }
        if (current_story_index == this.state.stories.length){
            const element = document.getElementById("stories_forward_button");
            element.hidden = 'True';
        }
        this.setState(state => ({
            ...state, 
            current_story_index: current_story_index
          }));
    }

    onBackwardClick = (e) => {
        let current_story_index = this.state.current_story_index;
        current_story_index -= 1;

        if (current_story_index == 0) {
            const element = document.getElementById("stories_backward_button");
            element.hidden = 'True';
        }
        if (current_story_index < this.state.stories.length){
            const element = document.getElementById("stories_forward_button");
            element.hidden = '';
        }
        this.setState(state => ({
            ...state, 
            current_story_index: current_story_index
          }));
    }

    render () {
        let card_data = undefined;
        let card_spine_title = "Не выложено";
        let color = "red"
        let button_text = "Отметить выложенным";
        let button_error = false;

        if ((this.state.stories.length > 0) && (this.state.current_story_index != this.state.stories.length)) {
            card_data = this.state.stories[this.state.current_story_index];            
            if (card_data.publicated) {
                color = "green"
                card_spine_title = "Выложено";
                button_text = "Отметить невыложенным"
                button_error = true;
            }
        }
        
        return(
            <div className="projectslist">
                <div className='control_buttons'>
                    <img id="stories_backward_button" src={stories_backward} hidden='True' onClick={this.onBackwardClick}/>
                    <div></div>
                    <img id="stories_forward_button" src={stories_forward} onClick={this.onForwardClick}/>
                </div>
                <div id="card_add_modal_placement" className="projectslist--modal"></div>
                <div id="projectslist">
                    <div className="projectslist--text">
                        <h1>{this.state.title}</h1>
                    </div>
                    <div className="projectslist--body">
                        <div className="counter">
                            <p>{this.state.current_story_index + 1} / {this.state.stories.length}</p>
                        </div>
                    </div>
                    <div className="projectslist--body">
                        
                        {   card_data ? 
                            <Card 
                                card_type={"story"}
                                card_spine_title={card_spine_title}
                                color={color}
                                openable={false}
                                has_id_in_url={false}
                                parent_prefix_url={"/project/" + this.state.project_id + "/content_plan/"}
                                card_prefix_url={card_data.id + "/stories/"}
                                key={card_data.id}
                                
                                cardEditModal={this.cardEditModal}
                                has_button_under={true}
                                button_text={button_text}
                                button_error={button_error}
                                onButtonClick={this.onButtonPublicatedFlagClick}
                                max_width={true}
                                {...card_data}
                            /> : ""
                        }
                        
                        {
                            ((this.state.current_story_index == this.state.stories.length) && (this.state.stories_loaded == true))
                            ? <CardAdd key={"add_card_key"} id={"add_card_id"} onClick={this.openProjectCreationModal}/>
                            : ""
                        }
                        
                    </div>
                </div>
            </div>
        );
    }
}



// {this.state.stories.map((card_data, index) => {
    // let card_spine_title = "Не выложено";
    // let color = "orange"
    // let button_text = "Отметить выложенным";
    // let button_error = false;
    // if (card_data.publicated) {
    //     color = "violet"
    //     card_spine_title = "Выложено";
    //     button_text = "Отметить невыложенным"
    //     button_error = true;
    // }
//     return <Card 
//         card_spine_title={card_spine_title}
//         color={color}
//         openable={false}
//         has_id_in_url={false}
//         parent_prefix_url={"/project/" + this.state.project_id + "/content_plan/"}
//         card_prefix_url={card_data.id + "/stories/"}
//         key={index.toString()}
        
//         cardEditModal={this.cardEditModal}
//         has_button_under={true}
//         button_text={button_text}
//         button_error={button_error}
//         onButtonClick={this.onButtonPublicatedFlagClick}
//         {...card_data}
//     />;
// }
    
// )}