import React from 'react';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom'


import { CardButton } from '../../atoms/Button/CardButton';
import { CardMenu } from '../../molecules/CardMenu/CardMenu';
import { CardImage } from '../../molecules/CardImage/CardImage';
import { Label } from "../../atoms/Label/Label";
import { DateTimeLabel } from "../../atoms/DateTimeLabel/DateTimeLabel";

import { metric } from '../../metric'

import './Card.scss';



export const Card = (
    {
      id,
      title,
      under_development,
      description,
      instagram_url,

      value,
      time_length,
      essence, 
      example, 
      materials,

      kpi, deadline,

      expected_outcome, influence_on_income, label_income, label_time, comments,

      problem, consequence, fear, false_settings, aims, journey,

      sex, age, geo, income, interests, busy_status, education, family,

      card_type,

      card_prefix_url,
      parent_prefix_url="/",
      color="red",
      has_id_in_url=true,
      openable=true,
      cardEditModal,
      has_sub_menu=true,
      has_share_in_sub_menu=false,
      cardShareModal,
      has_archive_in_sub_menu=false,
      archiveCard,
      archived,
      card_spine_title="",

      has_button_under=false,
      button_text = "",
      button_error = false,
      button_sub_text = undefined,
      onButtonClick = undefined,
      button_color = "primary",
      button_high = false,
      button_bold_text = false,

      points = [],
      max_width = false,
      nomargin = false,
      width_80 = false,
      relative = false,
      customOnClick,
    }) => {
  
  const onClickStub = (e) => {
    console.log("Stub click");
  }

  const onClickEdit = (e) => {
    console.log("edditing id");
    const payload = {
      "card_type": card_type,
      "card_id": id,
    }
    if (title) {
      payload["card_title"] = title
    }
    metric.npev("opened_CardEditModal", payload, 1)
    if (card_type == "project"){
      cardEditModal(id, title, description, instagram_url);
    }
    else if (card_type == "content_plan"){
      cardEditModal(id, title, description, value, time_length);
    }
    else if (card_type == "task_tracker"){
      cardEditModal(id, title, description, kpi, deadline);
    }
    else if (card_type == "task"){
      cardEditModal(id, title, expected_outcome, influence_on_income, deadline, label_income, label_time, comments);
    }
    else if (card_type == "story"){
      // essence, 
      // example, 
      // materials
      cardEditModal(id, title, essence, example, materials);
    }
    else if (card_type == "portrait") {
      cardEditModal(id, title, problem, consequence, fear, false_settings, aims, journey);
    }
    else if (card_type == "auditory") {
      cardEditModal(id, sex, age, problem, geo, income, interests, busy_status, education, family);
    }
  }

  const onClickShare = (e) => {
    if (card_type == "project"){
      cardShareModal(id, title);
    }
  }

  const onClickArchive = (e) => {
    console.log("Archived id: ", id);
    archiveCard(id, archived)
  }
  
  let menu_items = []
  if (has_sub_menu){
    menu_items = [
      {
        'type': 'button',
        'title': "Редактировать",
        'onClick': onClickEdit
      },
      // пока что убрал, чтобы не тратить время
      // {
      //   'type': 'button',
      //   'title': "Удалить",
      //   'onClick': onClickStub
      // }
    ]
    if (has_share_in_sub_menu) {
      menu_items.push(
        {
          'type': 'button',
          'title': "Поделиться",
          'onClick': onClickShare
        }
      )
    }
    if (has_archive_in_sub_menu) {
      if (archived){
        menu_items.push(
          {
            'type': 'button',
            'title': "Разархивировать",
            'onClick': onClickArchive
          }
        )
      } else {
        menu_items.push(
          {
            'type': 'button',
            'title': "Архивировать",
            'onClick': onClickArchive
          }
        )
      }
      
    }
  }
  
  let navigate = useNavigate();

  const openCard = (e) => {
    
    if (!openable){
      return
    }
    const target = e.target;
    console.log("target: ", target);
    const data = target.dataset;
    const id = data['id'];
    if (customOnClick){
      customOnClick(id);
      return 
    }
    let navigate_to_url = ""
    const parent_prefix_url = data["parent_prefix_url"];
    if (parent_prefix_url != "/"){
      navigate_to_url += parent_prefix_url;
    }
    const prefix_url = data['prefix_url'];
    console.log("prefix_url: ", prefix_url);
    
    const has_id_in_url = data['has_id_in_url'];
    // window.open(window.location.origin + prefix_url + id, "_self")
    const payload = {
      "card_type": card_type,
      "card_id": id,
    }
    if (title) {
      payload["card_title"] = title
    }
    metric.click("card", payload)

    if (prefix_url != ""){
      navigate_to_url += prefix_url;
      if (id && (has_id_in_url == "true")){
        navigate_to_url += id;
      }
      console.log("Opening: ", navigate_to_url);
      navigate(navigate_to_url);
    } else {
      console.log("No prefix");
    }
  }

  const onButtonClickWrap = async (e) => {
    const target = e.target;
    const data = target.dataset;

    const payload = {
      "card_type": card_type,
      "card_id": id,
    }
    if (title) {
      payload["card_title"] = title
    }
    metric.click("card_button", payload)

    onButtonClick(data['id']);
  }

  let card_classname = max_width ? "card card--max_width" : "card";
  card_classname = width_80 ? "card card--width_80" : "card";
  card_classname = nomargin ? "card card--nomargin": "card";
  if (relative) {
    card_classname += " card--relative";
  } 

  
  return(
    <div className={card_classname} data-id={id} key={id} id={id.toString()+"_card"}>
      <CardMenu card_spine_title={card_spine_title} data={id} id={id} color={color} menu_items={menu_items}/>
      <div data-has_id_in_url={has_id_in_url} data-parent_prefix_url={parent_prefix_url} data-prefix_url={card_prefix_url} data-id={id} onClick={openCard}>
        <div className="card--text card--text--unclickable">
          {title ? <h3>{title}</h3> : ""}
          {under_development ? <p className='under_development'><i>Раздел в разработке</i></p>: ""}
          {description ? <p className="description"><pre>{description}</pre></p> : "" }

          {value ? <p className="value"><u>Ценности:</u><br/><pre>{value}</pre></p> : ""}
          {time_length ? <p className="time_length"><u>Протяженность:</u><br/>{time_length}</p> : ""}
          {essence ? <p><u>Суть:</u><br/><pre>{essence}</pre></p> : ""}
          {example ? <p><u>Пример:</u><br/><pre>{example}</pre></p> : ""}
          {materials ? <p><u>Материалы:</u><br/><pre>{materials}</pre></p> : ""}

          {problem ? <p><u>Проблемы клиентов:</u><br/><pre>{problem}</pre></p> : ""}
          {consequence ? <p><u>Следствие этой проблемы:</u><br/><pre>{consequence}</pre></p> : ""}
          {fear ? <p><u>Страхи клиентов:</u><br/><pre>{fear}</pre></p> : ""}
          {false_settings ? <p><u>Ложные установки:</u><br/><pre>{false_settings}</pre></p> : ""}
          {aims ? <p><u>Мечты и цели клиентов:</u><br/><pre>{aims}</pre></p> : ""}
          {journey ? <p><u>Идеальный путь клиента:</u><br/><pre>{journey}</pre></p> : ""}

          {sex ? <p><u>Пол:</u><br/>{sex}</p> : ""}
          {age ? <p><u>Возраст:</u><br/>{age}</p> : ""}
          {geo ? <p><u>География:</u><br/>{geo}</p> : ""}
          {income ? <p><u>Уровень дохода:</u><br/>{income}</p> : ""}
          {interests ? <p><u>Интересы:</u><br/><pre>{interests}</pre></p> : ""}
          {busy_status ? <p><u>Занятость в течении дня:</u><br/><pre>{busy_status}</pre></p> : ""}
          {education ? <p><u>Образование:</u><br/><pre>{education}</pre></p> : ""}
          {family ? <p><u>Семейное положение:</u><br/>{family}</p> : ""}

          {kpi ? <p><u>Ключевая цель:</u><br/><pre>{kpi}</pre></p> : ""}

          {expected_outcome ? <p><u>Ожидаемый результат:</u><br/><pre>{expected_outcome}</pre></p> : ""}

          {influence_on_income ? <p><u>Вляние на прибыль:</u><br/><pre>{influence_on_income}</pre></p> : ""}

          {deadline? 
            <>
              <p><u>Срок:</u></p>
              <br/>
              <DateTimeLabel key_prop={"deadline"} data={deadline}/>
            </>
            
          : <></>}

          {label_income? 
            <>
              <p><u>Метка влияния на прибыль:</u></p>
              <br/>
              <Label key_prop={"label_picker--item--income"} color={"blue"} text={label_income}/> 
            </>
            
          : <></>}
          {label_time? 
            <>
              <p><u>Метка срока задачи:</u></p>
              <br/>
              <Label key_prop={"label_picker--item--time"} color={"green"} text={label_time}/> 
            </>
            
          : <></>}

          {comments ? <p><u>Комментарии:</u><br/><pre>{comments}</pre></p> : ""}

          { points 
            ? <ul>
                {points.map(
                  (point, index) => 
                    <li key={index}>{point}</li>
                  )
                }
              </ul>
            : ""
          }
        </div>
      </div>
      {instagram_url 
        ? <div className="card--text">
            <p className="instagram"><a href={"https://instagram.com/" + instagram_url} target="_blank">{instagram_url}</a></p>
          </div>
        : ""
      }
      <div className='card--padding' data-has_id_in_url={has_id_in_url} data-parent_prefix_url={parent_prefix_url} data-prefix_url={card_prefix_url} data-id={id} onClick={openCard}></div>
      {has_button_under ? 
        <div data-has_id_in_url={has_id_in_url} data-parent_prefix_url={parent_prefix_url} data-prefix_url={card_prefix_url} data-id={id} onClick={onButtonClickWrap}>
          <div className="card--text card--text--unclickable card--text--unclickable--no-margin">
            <CardButton button_color={button_color} text={button_text} sub_text={button_sub_text} high={button_high} bold_text={button_bold_text} error={button_error} onClick={onButtonClickWrap}/> 
          </div>
        </div>
        : ""}
    </div>
);
}



export const CardAdd = ({id, onClick}) => {
  const onButtonClickWrap = async (e) => {
    window.scrollTo(0, 0);
    onClick(e);
  }

  return(
    <div className="card" key={id} data={id} onClick={onButtonClickWrap}>
      <CardMenu data={id} id={id}/>
      <div className="card--add">
        <div className="plus">
          +
        </div>
      </div>
      <div className='card--padding'></div>
    </div>
);
}


Card.propTypes = {
  id: PropTypes.string.isRequired,
  card_prefix_url: PropTypes.string,
  color: PropTypes.oneOf(['red', 'orange', 'green', 'blue', 'violet', 'gray']),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  instagram: PropTypes.string,
  onClick: PropTypes.func,
};


Card.defaultProps = {
  onClick: undefined,
};
