import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./ProgressBarSteps.scss";


export const ProgressBarSteps = ({steps}) => {        
    return (
        <div className="stepper-wrapper">
            {steps.map((step, index) => {
                    return <div key={index.toString()} className={"stepper-item " + step.type}>
                            <div className="step-counter">{step.number}</div>
                            <div className="step-name">{step.name}</div>
                        </div>
                    }
                )
            }
        </div>
    )
}


const stepPropType = PropTypes.shape({
    number: PropTypes.number,
    name: PropTypes.string,
    type: PropTypes.oneOf(["completed", "active", ""])
})

ProgressBarSteps.propTypes = {
    steps: PropTypes.arrayOf(stepPropType)
};
