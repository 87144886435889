import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./CardSpine.scss";
import sub_menu_icon from "../../assets/icons/sub_menu_icon.svg";




export const CardSpine = ({id, data, color, hasMenu, onClick, title=""}) => {   
    
    const myclick = (e) => {
        
        console.log("click: ", e.target.dataset);
    }

    let classname = "card_spine card_spine--" + color
    if (title != "") {
        title = <p className='card_spine--title'>{title}</p>
    }
    let sub_menu = "";
    if (hasMenu == true) {
        sub_menu = <img src={sub_menu_icon} data-id={data}/>
    }
    // onClick={onClick}
    return (
        <div data-id={data} key={"card_id__" + id + "_spine"} id={"card_id__" + id + "_spine"} className={classname} onClick={onClick}> 
            <div></div>
            {title}
            {sub_menu}
            
        </div>
    )
}


CardSpine.propTypes = {
    color: PropTypes.oneOf(['red', 'orange', 'green', 'blue', 'violet', 'gray']),
    hasMenu: PropTypes.bool,
};
