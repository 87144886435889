import React from 'react';
import PropTypes from 'prop-types';


import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link
} from "react-router-dom";


import { Stories } from '../../pages/Stories/Stories';
import { ContentPlanList } from '../../pages/ContentPlanList/ContentPlanList';

import { ExpertList } from '../../pages/ExpertList/ExpertList';
import { Portraits } from '../../pages/Portraits/Portraits';

import { Auditory } from '../../pages/Auditory/Auditory';

import { ProjectsList } from '../../pages/ProjectsList/ProjectsList';
import { Project } from '../../pages/Project/Project';
import { Header } from '../../organisms/Header/Header';
import { LoginWindow } from '../../organisms/LoginWindow/LoginWindow';
import { Landing } from '../../pages/Landing_2_0/Landing';
import { SharingAccept } from '../../pages/SharingAccept/SharingAccept';
import { TaskList } from '../../pages/TaskList/TaskList';
import { Tasks } from '../../pages/Tasks/Tasks';

import './MenuView.scss';


// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: (
        // <div className="projectslist_area">
        //     <ProjectsList/>
        // </div>
//     ),
//   },
//   {
//     path: "project",
//     element: <div>About</div>,
//   },
// ]);

// createRoot(document.getElementById("root")).render(
//   <RouterProvider router={router} />
// );


export const MenuView = ({logged_in}) => {

    const logged_in_routes = <Routes>
        <Route path="/" element={
            <div className="projectslist_area">
                <ProjectsList/>
            </div>
        }/>
        <Route path="/login/" element={
            <div className="projectslist_area">
                <LoginWindow id={"login_window"}/>
            </div>
        }/>
        <Route path="/project/:slug" element={
            <div className="projectslist_area">
                <Project/>
            </div>
        }/>
        <Route path="/project/:slug/auditory/" element={
            <div className="projectslist_area">
                <Auditory/>
            </div>
        }/>
        <Route path="/project/:slug/expert/" element={
            <div className="projectslist_area">
                <ExpertList/>
            </div>
        }/>
        <Route path="/project/:slug/expert/portraits/" element={
            <div className="projectslist_area">
                <Portraits/>
            </div>
        }/>
        <Route path="/project/:slug/content_plan/" element={
            <div className="projectslist_area">
                <ContentPlanList/>
            </div>
        }/>
        <Route path="/project/:slug/content_plan/:slug/stories/" element={
            <div className="projectslist_area">
                <Stories/>
            </div>
        }/>
        <Route path="/project/:slug/task_tracker/" element={
            <div className="projectslist_area">
                <TaskList/>
            </div>
        }/>
        <Route path="/project/:slug/task_tracker/:slug/tasks/" element={
            <div className="projectslist_area">
                <Tasks/>
            </div>
        }/>
        <Route path="/share/:slug/" element={
            <div className="projectslist_area">
                <SharingAccept/>
            </div>
        }/>
    </Routes>

    const not_logged_in_routes = <Routes>
        <Route path="/" element={
            <div className="projectslist_area">
                <Landing />
            </div>
        }/>
        <Route path="/list/" element={
            <div className="projectslist_area">
                <LoginWindow id={"login_window"}/>
            </div>
        }/>
        <Route path="/login/" element={
            <div className="projectslist_area">
                <LoginWindow id={"login_window"}/>
            </div>
        }/>
    </Routes>

    return(
        <div className="menu_view">
            {/* <div className="header_area">
                <Header/>
            </div> */}
            <Router>
                <div>
                    {
                        logged_in 
                        ? logged_in_routes
                        : not_logged_in_routes
                    }
                </div>
            </Router>
        </div>
    );
}
