import React from 'react';
import PropTypes from 'prop-types';

import { CardButton } from '../../atoms/Button/CardButton';
import { CloseButton } from '../../atoms/CloseButton/CloseButton';
import { ProgressBarSteps } from '../../atoms/ProgressBarSteps/ProgressBarSteps';

import { CardMenu } from '../../molecules/CardMenu/CardMenu';
import { CardImage } from '../../molecules/CardImage/CardImage';

import { fetchModule } from '../../fetch'
import { metric } from '../../metric'


import './Subscribe.scss';


// export const LoginWindow_old = ({id, onSubmit, hasCloseButton, onClose}) => {
//   console.log("____onClose: ", hasCloseButton);
//   const closeButton = (hasCloseButton == true) ? <CloseButton onClick={onClose}/> : <></>
//   const tmp_token = getTmpTGToken();
//   console.log("outside: ", tmp_token);
//   const steps = [
//     {
//       number: 0,
//       name: "Открыть",
//       type: "completed"
//     },
//     {
//       number: 1,
//       name: "Нажать",
//       type: "active"
//     },
//     {
//       number: 2,
//       name: "Начать",
//       type: ""
//     },
//     {
//       number: 3,
//       name: "Готово",
//       type: ""
//     },
//   ]

//   return(
//     <div className="modal" key={id.toString()}>
//       <form>
//         <div className="modal--elements">
//           <div className="modal--elements--header">
//             <h3>Вход и регистрация</h3>
//             {closeButton}
//           </div>
//           <div className='modal--elements--progress_bar'>
//             <ProgressBarSteps steps={steps}/>
//           </div>
//           <h4>
//             Для входа или регистрации вам нужно:
//           </h4>
//           <ol>
//               <li>Нажать "Войти с Telegram"</li>
//               <li>Внутри бота <b>@producer_toools_bot</b> нажать кнопку "Начать"</li>
//               <li>Готово!</li>
//             </ol>
//         </div>
//       <CardButton text={"Войти с Telegram"} onClick={onSubmit}/>
//       </form>
//     </div>
// );
// }




export class Subscribe extends React.Component {
  constructor(id, hasCloseButton, onClose) {
      super();
      this.state = {
        tmp_token: "",
        auth_status_code: 0,
        auth_message: "",
        steps: [

          {
            number: 1,
            name: "Нажать",
            type: "completed"
          },
          {
            number: 2,
            name: "Войти",
            type: "active"
          },
          {
            number: 3,
            name: "Готово",
            type: ""
          },
        ]
      }
      this.id = id;
      this.hasCloseButton = hasCloseButton;
      this.onClose = onClose;
      this.nIntervId = false;
  }

  componentDidMount = () => {
      console.log('Subscribe mounted')
      
  }

  onSubmit = () => {
    // metric.click("subscribe_to_telegram_button");
    metric.click("redirect_to_login");

    const url = "/login?s=redirect_from_landing";
    window.open(url, "_self");
  }




  render() {
    return (
      <div className="subscribe">
        <div className="subscribe__modal" key={this.id.toString()}>
          <form>
            <div className="subscribe__modal--elements">
            <br></br>
              <div className="subscribe__modal--elements--header">
                
                <h3>В 3 простых шага</h3>
                {/* {closeButton} */}
              </div>
              <div className='subscribe__modal--elements--progress_bar'>
                <ProgressBarSteps steps={this.state.steps}/>
              </div>
              <h4>
                Все что нужно, это:
              </h4>
              <ul>
                <li><strike>Узнать об Инструменте</strike> – Готово</li>
              </ul>
              <ol>
                  
                  <li>Нажать кнопку войти</li>
                  <li>Войти с Телеграм</li>
                  <li>Готово!</li>
                </ol>
            </div>
          <CardButton button_color={"green"} text={"Войти"} onClick={this.onSubmit}/>
          </form>
        </div>
      </div>
    )
  }
}

// TODO: !!!!!!! Close modal button

Subscribe.propTypes = {
  id: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  hasCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
};
