import React from 'react';
import PropTypes from 'prop-types';
import './CardImage.scss';


export default class LazyImage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loaded: false,
        error: false,
        style: {}
      };
    }
  
    componentDidMount() {
      const img = new Image();
      img.onload = () => {
        this.setState({
          loaded: true
        });
        // this.props.onLoadUpper();
      };
      img.onerror = () => {
        this.setState({
          error: true
        });
      };
      img.src = this.props.src;

    }
  
    render() {
        let style = {}
        if (this.props.width != undefined){style.width = this.props.width}
        if (this.props.height != undefined){style.height = this.props.height}
        
      if (this.state.error) {
        return <img
          id={this.props.id}
          className={this.props.className}
          style={style}
          src={this.props.unloadedSrc}
          alt={this.props.alt} />
      } else if (!this.state.loaded) {
        return <img
          id={this.props.id}
          style={style}
          className={this.props.className}
          src={this.props.unloadedSrc}
          alt={this.props.alt} />
      }

      let to_ret = <img
      id={this.props.id}
      style={style}
      className={this.props.className}
      src={this.props.src}
      alt={this.props.alt} />      
      return to_ret
    }
}


export class CardImage extends React.Component {
    render() {
        return (
            <LazyImage
                id={this.props.id}
                className={"card_image " + this.props.className}
                width={this.props.width}
                height={this.props.height}
                src={this.props.src}
                alt={this.props.alt}
            />
        )
    }
}


CardImage.propTypes = {
    id: PropTypes.string,
    width: PropTypes.string.isRequired,
    height: PropTypes.string.isRequired,
    src: PropTypes.any,
    alt: PropTypes.string,
};


CardImage.defaultProps = {
};

