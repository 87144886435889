import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { Card, CardAdd } from '../../organisms/Card/Card';

import { Modal } from '../../organisms/Modal/Modal';

import './Tasks.scss';

import { fetchModule } from '../../fetch'

import stories_forward from "../../assets/icons/stories_forward.svg";
import stories_backward from "../../assets/icons/stories_backward.svg";
import grid_view from "../../assets/icons/grid_view.svg";
import stories_view from "../../assets/icons/stories_view.svg";



export class Tasks extends React.Component {
    constructor({tasks = [], stub=false}) {
        console.log("Tasks");
        super();
        this.stub = stub;
        console.log("stub constr: ", this.stub);
        this.state = {
            stories_view: false, //true,
            show_archived: false,
            title: "Задачи",
            current_task_index: 0,
            project_id: -1,
            task_tracker_id: -1,
            tasks: tasks,
            tasks_loaded: false
        }
        this.PROJECT_CREATE_MODAL_ID = "task_create_modal_id"
    }

    componentDidMount = () => {
        this.getContentPlans();
    }

    cardEditModal = (task_tracker_id, title, expected_outcome, influence_on_income, deadline, label_income, label_time, comments) => {
        console.log("cardEditModal___card_id: ", task_tracker_id, expected_outcome, influence_on_income, deadline, label_income, label_time, comments);
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = "projectslist projectslist--blurred"
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        const project_create_modal_fields = [
            {
                "form_key": "id",
                "name": "",
                "input_type": "hidden",
                "value": task_tracker_id
            },
            {
                "form_key": "title",
                "name": "Название задачи",
                "input_type": "text",
                "value": title
            },
            {
                "form_key": "expected_outcome",
                "name": "Ожидаемый результат",
                "description": "Подробное описание ожидаемого результата",
                "input_type": "textarea",
                "value": expected_outcome
            },
            {
                "form_key": "influence_on_income",
                "name": "Вляние на прибыль",
                "description": "Какое влияние на прибыль оказывает выполнение этой задачи",
                "input_type": "textarea",
                "value": influence_on_income
            },
            {
                "form_key": "deadline",
                "name": "Срок выполнения задачи",
                "description": "Когда эта задача должна быть выполнена",
                "input_type": "datetime-local",
                "value": deadline
            },
            {
                "form_key": "label_income",
                "name": "Метка влияния на прибыль",
                "description": "Какое влияние на прибыль оказывает эта задача",
                "input_type": "picker",
                "values": ["низкое", "среднее", "высокое"],
                "label_color": "blue",
                "value": label_income,
            },
            {
                "form_key": "label_time",
                "name": "Метка срока задачи",
                "description": "Как быстро можно выполнить задачу",
                "input_type": "picker",
                "values": ["медленно", "средне", "быстро"],
                "label_color": "green",
                "value": label_time,
            },
            {
                "form_key": "comments",
                "name": "Комментарии",
                "input_type": "textarea",
                "value": comments
            },

        ]
        const project_create_modal = <Modal 
            id={this.PROJECT_CREATE_MODAL_ID} 
            modal_title={"Редактирование: " + title}
            fields={project_create_modal_fields}
            closeFunc={this.closeProjectCreationModal}
            onSubmit={this.onSubmitUpdateProject}
            submitButtonName={"Сохранить"}
        />
        modal_element.render(project_create_modal);
    }

    getContentPlans = async () => {
        console.log("stub get: ", this.stub);
        if (this.stub){
            return {}
        }
        const project_id = window.location.pathname.split("/task_tracker")[0].replace('project', '').replace(/\//g, '');
        console.log("project_id: ", project_id);
        const task_tracker_id = window.location.pathname.split("/task_tracker")[1].replace('tasks', '').replace(/\//g, '');
        console.log("task_tracker_id: ", task_tracker_id);
        this.setState(state => ({
            ...state, 
            tasks_loaded: true,
            project_id: project_id,
            task_tracker_id: task_tracker_id,
        }));
        try {
          const response = await fetchModule.doGet({path: '/project/' + project_id + "/task_tracker/" + task_tracker_id + "/tasks/"});
          if ((response.status >= 200) && (response.status < 400)) {
              let json = await response.json();
              console.log("tasks: ", json["tasks"]);

              if (json["tasks"].length == 0) {
                const element = document.getElementById("stories_forward_button");
                if (element){
                    element.hidden = 'True';
                }
              }
              this.setState(state => ({
                ...state, 
                tasks: json["tasks"]
              }));  
              return json;
          }
          else if ((response.status >= 400) && (response.status < 404)) {
            let json = await response.json();
            return json;
          } 
          else {
              throw response.status; 
          }
        } catch (error) {
            throw error;
        }
    }

    closeCardSettingsModal = (card_id) => {
        const element = document.getElementById(card_id + "__card_menu_modal")
        if (element){
            element.hidden = "True";
        }
    }

    closeProjectCreationModal = (e) => {
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = ""
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        modal_element.render(<></>);
    }

    openProjectCreationModal = (e) => {
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = "projectslist projectslist--blurred"
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        const project_create_modal_fields = [
            {
                "form_key": "title",
                "name": "Название задачи",
                "input_type": "text",
            },
            {
                "form_key": "expected_outcome",
                "name": "Ожидаемый результат",
                "description": "Подробное описание ожидаемого результата",
                "input_type": "textarea",
            },
            {
                "form_key": "influence_on_income",
                "name": "Вляние на прибыль",
                "description": "Какое влияние на прибыль оказывает выполнение этой задачи",
                "input_type": "textarea",
            },
            {
                "form_key": "deadline",
                "name": "Срок выполнения задачи",
                "description": "Когда эта задача должна быть выполнена",
                "input_type": "datetime-local",
            },
            {
                "form_key": "label_income",
                "name": "Метка влияния на прибыль",
                "description": "Какое влияние на прибыль оказывает эта задача",
                "input_type": "picker",
                "label_color": "blue",
                "values": ["низкое", "среднее", "высокое"],
            },
            {
                "form_key": "label_time",
                "name": "Метка срока задачи",
                "description": "Как быстро можно выполнить задачу",
                "input_type": "picker",
                "label_color": "green",
                "values": ["медленно", "средне", "быстро"],
            },
            {
                "form_key": "comments",
                "name": "Комментарии",
                "input_type": "textarea"
            },
        ]
        const project_create_modal = <Modal 
            id={this.PROJECT_CREATE_MODAL_ID} 
            modal_title={"Добавить задачу"}
            fields={project_create_modal_fields}
            closeFunc={this.closeProjectCreationModal}
            onSubmit={this.onSubmitNewProject}
            submitButtonName={"Добавить задачу"}
        />
        modal_element.render(project_create_modal);
    }

    onSubmitNewProject = async (e) => {
        console.log(e)
        const project_id = this.state.project_id;
        const task_tracker_id = this.state.task_tracker_id;
        const form_keys_array = ["title", "expected_outcome", "influence_on_income", "deadline", "label_income", "label_time", "comments"]
        // for key_name in form_keys_array
        const tasks_data = {}
        form_keys_array.map((key_name, index) => {
                console.log("_________: ", this.PROJECT_CREATE_MODAL_ID + "_" + key_name);
                const element = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + key_name)
                if (element.value.length != 0){
                    console.log(key_name, ": ", element.value);
                    tasks_data[key_name] = element.value;
                } else {
                    console.log(key_name, ": ", element.innerText);
                    tasks_data[key_name] = element.innerText;
                }
            }
        )
        console.log("tasks_data: ", tasks_data);

        try {
            const response = await fetchModule.doPost({path: '/project/' + project_id + "/task_tracker/" + task_tracker_id + "/tasks/", body: tasks_data});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                const element = document.getElementById("stories_forward_button");
                if (element){
                    element.hidden = '';
                }
                this.setState(state => ({
                  ...state, 
                  tasks: json["tasks"]
                }));
                this.closeProjectCreationModal(null);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
    }

    onSubmitUpdateProject = async (e) => {
        console.log(e)
        const project_id = this.state.project_id;
        const task_tracker_id = this.state.task_tracker_id;
        
        const form_keys_array = ["title", "expected_outcome", "influence_on_income", "deadline", "label_income", "label_time", "comments"]
        // for key_name in form_keys_array
        const tasks_data = {}
        form_keys_array.map((key_name, index) => {
                console.log("_________: ", this.PROJECT_CREATE_MODAL_ID + "_" + key_name);
                const element = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + key_name)
                if (element.value.length != 0){
                    console.log(key_name, ": ", element.value);
                    tasks_data[key_name] = element.value;
                } else {
                    console.log(key_name, ": ", element.innerText);
                    tasks_data[key_name] = element.innerText;
                }
                
            }
        )
        const element_id = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + "id")
        const task_id = element_id.value;
        console.log("task_id: ", task_id);
        console.log("tasks_data: ", tasks_data);

        try {
            const response = await fetchModule.doPut({path: '/project/' + project_id + "/task_tracker/" + task_tracker_id + "/tasks/" + task_id, body: tasks_data});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                  ...state, 
                  tasks: json["tasks"]
                }));
                this.closeProjectCreationModal(null);
                this.closeCardSettingsModal(task_id);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                this.closeCardSettingsModal(task_id);
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
        
    }

    onButtonChangeStatusFlagClick = async (task_id) => {
        const project_id = this.state.project_id;
        const task_tracker_id = this.state.task_tracker_id;
        try {
            const response = await fetchModule.doPut({path: '/project/' + project_id + "/task_tracker/" + task_tracker_id + "/tasks/" + task_id + "/change_status/"});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                  ...state, 
                  tasks: json["tasks"]
                }));
                this.closeProjectCreationModal(null);
                this.closeCardSettingsModal(task_id);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                this.closeCardSettingsModal(task_id);
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
    }

    onForwardClick = (e) => {
        let current_task_index = this.state.current_task_index;
        current_task_index += 1;
        if (!this.state.show_archived){
            while (current_task_index < this.state.tasks.length) {
                if (this.state.tasks[current_task_index].archived){
                    current_task_index += 1;
                } else {
                    break;
                }
            }
        }

        if (current_task_index > 0) {
            const element = document.getElementById("stories_backward_button");
            if (element){
                element.hidden = '';
            }
            
        } else if (current_task_index == this.state.tasks.length){
            const element = document.getElementById("stories_forward_button");
            if (element){
                element.hidden = 'True';
            }
        }
        this.setState(state => ({
            ...state, 
            current_task_index: current_task_index
          }));
        
    }

    onBackwardClick = (e) => {
        let current_task_index = this.state.current_task_index;
        const starting_task_index = this.state.current_task_index;
        current_task_index -= 1;
        if (!this.state.show_archived){
            while (current_task_index > 0) {
                if (this.state.tasks[current_task_index].archived){
                    current_task_index -= 1;
                } else {
                    break;
                }
            }
        }

        if (current_task_index == 0) {
            if (current_task_index != starting_task_index) {
                current_task_index = starting_task_index;
            }
            const element = document.getElementById("stories_backward_button");
            if (element){
                element.hidden = 'True';
            }
        } else if (current_task_index < this.state.tasks.length){
            const element = document.getElementById("stories_forward_button");
            if (element){
                element.hidden = '';
            }
        }
        this.setState(state => ({
            ...state, 
            current_task_index: current_task_index
          }));
    }

    archiveCard = async (task_id, current_archive_status) => {
        console.log("Archiving project");
        console.log("current_archive_status: ", current_archive_status);
        const tasks_data = {}
        const project_id = this.state.project_id;
        const task_tracker_id = this.state.task_tracker_id;
        tasks_data['archived'] = !current_archive_status;
        console.log("project_id: ", project_id);
        console.log("task_tracker_id: ", task_tracker_id);
        console.log("tasks_data: ", tasks_data);

        try {
            const response = await fetchModule.doPut({path: '/project/' + project_id + "/task_tracker/" + task_tracker_id + "/tasks/" + task_id, body: tasks_data});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                  ...state, 
                  projects_data: json["projects"]
                }));
                this.closeProjectCreationModal(null);
                this.closeCardSettingsModal(project_id);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                this.closeCardSettingsModal(project_id);
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
    }

    showArchived = (e) => {
        let show_archived = !this.state.show_archived;
        this.setState(state => ({
            ...state, 
            show_archived: show_archived
        }));
    }

    changeView = (e) => {
        let stories_view = !this.state.stories_view;
        this.setState(state => ({
            ...state, 
            stories_view: stories_view
        }));
    }

    openStoriesView = (card_id) => {
        this.setState(state => ({
            ...state, 
            stories_view: true,
            current_task_index: parseInt(card_id)
        }));
    }

    render () {
        let card_data = undefined;
        let card_spine_title = "Новое";
        let color = "blue"
        let button_text = "Взять в работу";
        let button_error = false;

        if ((this.state.tasks.length > 0) && (this.state.current_task_index != this.state.tasks.length)) {
            card_data = this.state.tasks[this.state.current_task_index];            
            if (card_data.status == "new") {
                color = "blue"
                card_spine_title = "Новое";
                button_text = "Взять в работу" // "Отметить невыполненым"
                // button_error = true;
                button_error = false;
            } else if (card_data.status == "in_progress") {
                color = "green"
                card_spine_title = "В работе";
                button_text = "Завершить" // "Отметить невыполненым"
                // button_error = true;
                button_error = false;
            } else if (card_data.status == "done") {
                color = "red"
                card_spine_title = "Готово";
                button_text = "Отметить новым" // "Отметить невыполненым"
                // button_error = true;
                button_error = true;
            }
        }
        
        return(
            <div className="projectslist">
                { this.state.stories_view 
                ?
                <div className='control_buttons'>
                    <img id="stories_backward_button" src={stories_backward} hidden='True' onClick={this.onBackwardClick}/>
                    <div></div>
                    <img id="stories_forward_button" src={stories_forward} onClick={this.onForwardClick}/>
                </div>
                : <></>
                }
                
                <div id="card_add_modal_placement" className="projectslist--modal"></div>
                
                <div id="projectslist">
                    <div className="projectslist--text">
                        <h1>{this.state.title}</h1>
                    </div>
                    <div className="projectslist--text">
                        {this.state.show_archived 
                        ? <p onClick={this.showArchived}>Скрыть архивированные</p>
                        : <p onClick={this.showArchived}>Показать архивированные</p>
                        }
                    </div>  
                    <div className="projectslist--text">
                    <div className="projectslist--view_switch">
                        <p>Отображение:</p>
                        {this.state.stories_view 
                        ?
                        <div className="projectslist--view_switch--toggle">
                            <div className="projectslist--view_switch--toggle--item" onClick={this.changeView}>
                                <img src={grid_view}/>
                            </div>   
                            <div className="projectslist--view_switch--toggle--item--active" onClick={this.changeView}>
                                <img src={stories_view}/>
                            </div>   
                        </div>
                        :
                        <div className="projectslist--view_switch--toggle">
                            <div className="projectslist--view_switch--toggle--item--active" onClick={this.changeView}>
                                <img src={grid_view}/>
                            </div>   
                            <div className="projectslist--view_switch--toggle--item" onClick={this.changeView}>
                                <img src={stories_view}/>
                            </div>   
                        </div>
                        }
                        
                        
                        {/* {this.state.stories_view 
                        ?
                        <div onClick={this.changeView}>
                            <img src={grid_view}/>
                            <p>сеткой</p>
                        </div>   
                        : 
                        <div onClick={this.changeView}>
                            <img src={stories_view}/>
                        </div>   
                        } */}
                    </div>
                    </div>  
                    <div className="projectslist--body">
                        { this.state.stories_view ?
                        <div className="counter">
                            <p>{this.state.current_task_index + 1} / {this.state.tasks.length}</p>
                        </div>
                        : <></>
                        }
                    </div>
                    <div className="projectslist--body">
                        { !this.state.stories_view
                        ?
                        this.state.tasks.map((task_signle_data, index) => {
                            if ((this.state.show_archived && task_signle_data.archived) || (!task_signle_data.archived)){
                                if (task_signle_data.status == "new") {
                                    color = "blue"
                                    card_spine_title = "Новое";
                                    button_text = "Взять в работу" // "Отметить невыполненым"
                                    // button_error = true;
                                    button_error = false;
                                } else if (task_signle_data.status == "in_progress") {
                                    color = "green"
                                    card_spine_title = "В работе";
                                    button_text = "Завершить" // "Отметить невыполненым"
                                    // button_error = true;
                                    button_error = false;
                                } else if (task_signle_data.status == "done") {
                                    color = "red"
                                    card_spine_title = "Готово";
                                    button_text = "Отметить новым" // "Отметить невыполненым"
                                    // button_error = true;
                                    button_error = true;
                                }

                                return <Card 
                                        card_type={"task"}
                                        card_spine_title={card_spine_title}
                                        openable={true}
                                        customOnClick={this.openStoriesView}
                                        has_id_in_url={false}
                                        parent_prefix_url={"/project/" + this.state.project_id + "/task_tracker/"}
                                        card_prefix_url={task_signle_data.id + "/tasks/"}
                                        key={task_signle_data.id}
                                        relative={true}
                                        color={task_signle_data.archived ? 'gray': color}
                                        
                                        cardEditModal={this.cardEditModal}
                                        has_button_under={true}
                                        button_text={button_text}
                                        button_error={button_error}
                                        button_color={'orange'}
                                        onButtonClick={this.onButtonChangeStatusFlagClick}
                                        has_archive_in_sub_menu={true}
                                        archiveCard={this.archiveCard}
                                        
                                        {...task_signle_data}
                                    />
                            }
                        })
                        : <></>
                        }
                        {!this.state.stories_view 
                        ? <CardAdd key={"add_card_key_2"} id={"add_card_id"} onClick={this.openProjectCreationModal}/>
                        : <></>
                        }
                        
                        { this.state.stories_view && card_data ? 
                            ((this.state.show_archived && card_data.archived) || (!card_data.archived)) 
                                ?
                                    <Card 
                                        card_type={"task"}
                                        card_spine_title={card_spine_title}
                                        openable={false}
                                        has_id_in_url={false}
                                        parent_prefix_url={"/project/" + this.state.project_id + "/task_tracker/"}
                                        card_prefix_url={card_data.id + "/tasks/"}
                                        key={card_data.id}
                                        relative={true}
                                        color={card_data.archived ? 'gray': color}
                                        
                                        cardEditModal={this.cardEditModal}
                                        has_button_under={true}
                                        button_text={button_text}
                                        button_error={button_error}
                                        button_color={'orange'}
                                        onButtonClick={this.onButtonChangeStatusFlagClick}
                                        max_width={true}
                                        has_archive_in_sub_menu={true}
                                        archiveCard={this.archiveCard}
                                        
                                        {...card_data}
                                    />
                                : ""
                             : ""
                        }
                        
                        {
                            ((this.state.current_task_index == this.state.tasks.length) && (this.state.tasks_loaded == true))
                            ? <CardAdd key={"add_card_key"} id={"add_card_id"} onClick={this.openProjectCreationModal}/>
                            : ""
                        }
                        
                    </div>
                </div>
                
                
            </div>
        );
    }
}
