import React from 'react';
import PropTypes from 'prop-types';

import { CardButton } from '../../atoms/Button/CardButton';
import { CloseButton } from '../../atoms/CloseButton/CloseButton';
import { CardMenu } from '../../molecules/CardMenu/CardMenu';
import { LabelPicker } from '../../molecules/LabelPicker/LabelPicker';

import './Modal.scss';


export const Modal = ({id, modal_title, fields, onSubmit, closeFunc, submitButtonName="Создать проект"}) => {

  const onClick = (e) => {
    console.log("picker_click", e);
  }
// onChange={onChange}
  return(
    <div className="modal" key={id.toString()}>
      <form>
        <div className="modal--elements">
          <div className="modal--elements--header">
            <h3>{modal_title}</h3>
            <CloseButton onClick={closeFunc}/>
          </div>
          <br/>
          <div className='fixed_height_container'>
            <div className='scrollable'>
              {fields.map((field, index) => 
                  // <Card key={index.toString()} id={index.toString()} {...card_data}  />
                  <div key={index.toString()} className="modal--element">
                    <b>{field.name}</b>
                    {field.description ? <div className="hint"><p>{field.description}</p></div> : <br/>}
                    {field.input_type == "picker" 
                    ? 
                    <>
                      <LabelPicker id={id} picked_color={field.label_color} values={field.values} form_key={field.form_key} defaultValue={field.value} onclick={onClick} />
                      {/* <input key={field.form_key} id={"modal_" + id + "_" + field.form_key} type={"hidden"} defaultValue={field.value} ></input> */}
                    </>
                    : field.input_type == "textarea" 
                    ? <textarea class="modal--textarea" key={field.form_key} id={"modal_" + id + "_" + field.form_key} defaultValue={field.value} ></textarea> 
                    : <input class="modal-input" key={field.form_key} id={"modal_" + id + "_" + field.form_key} type={field.input_type} defaultValue={field.value} ></input>
                    }
                    
                  </div>
              )}
            </div>
            
          </div>
          
        </div>
      <CardButton text={submitButtonName} onClick={onSubmit}/>
      </form>
    </div>
);
}

// TODO: !!!!!!! Close modal button

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  modal_title: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func,
};
