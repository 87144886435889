import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./Label.scss";


export const Label = ({id, key_prop, text, color, onClick}) => {
    let classname = "label label--"+color
    return (
        <div key={key_prop} id={id} className={classname} onClick={onClick}>
            <p className={"label_p"}>{text}</p>
        </div>
    )
}
