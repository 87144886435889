import React, {Suspense} from 'react';
import PropTypes from 'prop-types';

import "./CardMenu.scss";

import { CardSpine } from '../../atoms/Spine/CardSpine';


const openCardSettingsModal = (e) => {
    // 1__card_menu_modal
    const target = e.target;
    const card_id = target.dataset["id"];
    const element = document.getElementById(card_id + "__card_menu_modal")
    element.hidden = "";
}

const closeCardSettingsModal = (e) => {
    // 1__card_menu_modal
    const target = e.target;
    const card_id = target.dataset["id"];
    const element = document.getElementById(card_id + "__card_menu_modal")
    element.hidden = "True";
}


const onClickStub = () => {

}



export const CardMenuModal = ({id, data, menu_items}) => {
    return (
        <div data-id={data} key={id} id={id+"__card_menu_modal"} className="card_menu_modal" hidden='True'>
            <div data-id={data} className='card_menu_modal--header' onClick={closeCardSettingsModal}>
                Закрыть
            </div>
            <div className='card_menu_modal--items'>
                {menu_items.map((item, index) => 
                    <div key={data + "_card_menu_modal__item__" + index} data-id={data} className='card_menu_modal--item' onClick={item.onClick}>
                        <p>{item.title}</p>
                    </div>
                )}
            </div>
        </div>
    )
}


export const CardMenu = ({id, data, color, menu_items = [], card_spine_title=""}) => {        
    let classname = "card_menu card_menu--" + color
    let onClickFunc = onClickStub;
    let hasMenu = false;
    if (menu_items.length > 0) {
        hasMenu = true;
        onClickFunc = openCardSettingsModal;
    }
    return (
        <div data-id={data} key={id+"_menu"} className={classname}>
            <CardSpine title={card_spine_title} data={data} id={id} color={color} hasMenu={hasMenu} onClick={onClickFunc}/>
            <div data-id={data} id={"card_menu_modal_anchor_" + id} className="card_menu_modal--anchor">
                <CardMenuModal data={data} id={id} menu_items={menu_items}/>
            </div>
        </div>
    )
}


CardMenu.propTypes = {
    id: PropTypes.string,
    card_id: PropTypes.string,
    color: PropTypes.oneOf(['red', 'orange', 'green', 'blue', 'violet', 'gray']),
    menu_items: PropTypes.array = []
};


CardMenuModal.propTypes = {
    id: PropTypes.string,
    menu_items: PropTypes.array = []
}