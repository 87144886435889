import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { Card, CardAdd } from '../../organisms/Card/Card';

import { Modal } from '../../organisms/Modal/Modal';

import './Portraits.scss';

import { fetchModule } from '../../fetch'




export class Portraits extends React.Component {
    constructor() {
        console.log("Portraits");
        super();
        this.state = {
            project_id: -1,
            portraits: [],
            portraits_loaded: false,
        }
        this.PROJECT_CREATE_MODAL_ID = "project_create_modal_id"
    }

    componentDidMount = () => {
        this.getContentPlans();
    }

    cardEditModal = (portrait_id, title, problem, consequence, fear, false_settings, aims, journey) => {
        console.log("cardEditModal___card_id: ", portrait_id, title, title, problem, consequence, fear, false_settings, aims, journey);
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = "projectslist projectslist--blurred"
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        const project_create_modal_fields = [
            {
                "form_key": "id",
                "name": "",
                "input_type": "hidden",
                "value": portrait_id
            },
            {
                "form_key": "title",
                "name": "Общее название этой группы",
                "description": "Например: новички, опытные или интересующиеся",
                "input_type": "text",
                "value": title
            },
            {
                "form_key": "problem",
                "name": "Проблемы клиентов",
                "description": "С какими проблеми сталкиваются клиенты?",
                "input_type": "textarea",
                "value": problem
            },
            {
                "form_key": "consequence",
                "name": "Следствие этой проблемы",
                "description": "К чему приводит эта проблема?",
                "input_type": "textarea",
                "value": consequence
            },
            {
                "form_key": "fear",
                "name": "Страхи клиентов",
                "description": "Чего боятся клиенты?",
                "input_type": "text",
                "value": fear
            },
            {
                "form_key": "false_settings",
                "name": "Ложные установки у клиентов",
                "description": "Какие стереотипы клиентов ошибочны?",
                "input_type": "text",
                "value": false_settings
            },
            {
                "form_key": "aims",
                "name": "Мечты и цели клиентов",
                "description": "О чем мечтают и чего хотят клиенты?",
                "input_type": "text",
                "value": aims
            },
            {
                "form_key": "journey",
                "name": "Идеальный путь клиента",
                "description": "Какой путь нужно пройти клиенту чтобы достичь своей цели?",
                "input_type": "text",
                "value": journey
            },
        ]
        const project_create_modal = <Modal 
            id={this.PROJECT_CREATE_MODAL_ID} 
            modal_title={"Редактирование: " + title}
            fields={project_create_modal_fields}
            closeFunc={this.closeProjectCreationModal}
            onSubmit={this.onSubmitUpdateProject}
            submitButtonName={"Сохранить"}
        />
        modal_element.render(project_create_modal);
    }

    getContentPlans = async () => {
        const project_id = window.location.pathname.replace('project', '').replace('expert/portraits', '').replace(/\//g, '');
        console.log("project_id: ", project_id);
        this.setState(state => ({
            ...state, 
            project_id: project_id,
        }));
        try {
          const response = await fetchModule.doGet({path: '/project/' + project_id + "/expert/portraits/"});
          if ((response.status >= 200) && (response.status < 400)) {
              let json = await response.json();
              console.log("portraits: ", json["portraits"]);
              this.setState(state => ({
                ...state, 
                portraits_loaded: true,
                portraits: json["portraits"]
              }));  
              return json;
          }
          else if ((response.status >= 400) && (response.status < 404)) {
            let json = await response.json();
            return json;
          } 
          else {
              throw response.status; 
          }
        } catch (error) {
            throw error;
        }
    }

    closeCardSettingsModal = (card_id) => {
        const element = document.getElementById(card_id + "__card_menu_modal")
        element.hidden = "True";
    }

    closeProjectCreationModal = (e) => {
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = ""
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        modal_element.render(<></>);
    }

    openProjectCreationModal = (e) => {
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = "projectslist projectslist--blurred"
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        const project_create_modal_fields = [
            {
                "form_key": "title",
                "name": "Общее название этой группы",
                "description": "Например: новички, опытные или интересующиеся",
                "input_type": "text"
            },
            {
                "form_key": "problem",
                "name": "Проблемы клиентов",
                "description": "С какими проблеми сталкиваются клиенты?",
                "input_type": "textarea"
            },
            {
                "form_key": "consequence",
                "name": "Следствие этой проблемы",
                "description": "К чему приводит эта проблема?",
                "input_type": "textarea"
            },
            {
                "form_key": "fear",
                "name": "Страхи клиентов",
                "description": "Чего боятся клиенты?",
                "input_type": "text"
            },
            {
                "form_key": "false_settings",
                "name": "Ложные установки у клиентов",
                "description": "Какие стереотипы клиентов ошибочны?",
                "input_type": "text"
            },
            {
                "form_key": "aims",
                "name": "Мечты и цели клиентов",
                "description": "О чем мечтают и чего хотят клиенты?",
                "input_type": "text"
            },
            {
                "form_key": "journey",
                "name": "Идеальный путь клиента",
                "description": "Какой путь нужно пройти клиенту чтобы достичь своей цели?",
                "input_type": "text"
            },
        ]
        const project_create_modal = <Modal 
            id={this.PROJECT_CREATE_MODAL_ID} 
            modal_title={"Создание портета клиента"}
            fields={project_create_modal_fields}
            closeFunc={this.closeProjectCreationModal}
            onSubmit={this.onSubmitNewProject}
            submitButtonName={"Создать портрет"}
        />
        modal_element.render(project_create_modal);
    }

    onSubmitNewProject = async (e) => {
        console.log(e)
        const form_keys_array = ["title", "problem", "consequence", "fear", "false_settings", "aims", "journey"]
        const project_id = this.state.project_id;
        // for key_name in form_keys_array
        const project_data = {}
        form_keys_array.map((key_name, index) => {
                console.log("_________: ", this.PROJECT_CREATE_MODAL_ID + "_" + key_name);
                const element = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + key_name)
                console.log(key_name, ": ", element.value);
                project_data[key_name] = element.value;
            }
        )
        console.log("project_data: ", project_data);

        try {
            const response = await fetchModule.doPost({path: '/project/' + project_id + "/expert/portraits/", body: project_data});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                  ...state, 
                  portraits: json["portraits"]
                }));
                this.closeProjectCreationModal(null);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
    }

    onSubmitUpdateProject = async (e) => {
        console.log(e)
        const form_keys_array = ["title", "problem", "consequence", "fear", "false_settings", "aims", "journey"]
        // for key_name in form_keys_array
        const portrait = {}
        form_keys_array.map((key_name, index) => {
                console.log("_________: ", this.PROJECT_CREATE_MODAL_ID + "_" + key_name);
                const element = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + key_name)
                console.log(key_name, ": ", element.value);
                portrait[key_name] = element.value;
            }
        )
        const element_id = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + "id")
        const portrait_id = element_id.value;
        console.log("portrait_id: ", portrait_id);
        console.log("portrait: ", portrait);
        const project_id = this.state.project_id;

        try {
            const response = await fetchModule.doPut({path: '/project/' + project_id + "/expert/portraits/" + portrait_id, body: portrait});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                  ...state, 
                  portraits: json["portraits"]
                }));
                this.closeProjectCreationModal(null);
                this.closeCardSettingsModal(portrait_id);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                this.closeCardSettingsModal(portrait_id);
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
        
    }

    render () {
        return(
            <div className="projectslist">
                <div id="card_add_modal_placement" className="projectslist--modal"></div>
                <div id="projectslist">
                    <div className="projectslist--text">
                        <h1>Портреты клиентов</h1>
                    </div>
                    <div className="projectslist--body">
                        {this.state.portraits.map((card_data, index) => 
                            <Card
                                card_type={"portrait"}
                                has_id_in_url={false}
                                parent_prefix_url={"/project/" + this.state.project_id + "/portraits/"}
                                card_prefix_url={""}
                                key={index.toString()}
                                cardEditModal={this.cardEditModal}
                                {...card_data}
                            />
                        )}
                        {
                            ((this.state.portraits.length == 0) && (this.state.portraits_loaded == true))
                            ? <div className="projectslist--text">
                                <h2>Портреты клиентов ещё не созданы</h2>
                                <h3>Нажмите + чтобы создать портет клиента</h3>
                                <CardAdd key={"add_card_key"} id={"add_card_id"} onClick={this.openProjectCreationModal}/>
                              </div>
                            : <></> 
                        }
                    </div>
                </div>
            </div>
        );
    }
}
