import React from 'react';

import { Placeholder } from '../../atoms/Placeholder/Placeholder';

import './MenuViewPlaceholder.scss';

export const MenuViewPlaceholder = () => {
  const header_props = {
    width: "100%",
    height: "70px",
    margin: "0px",
    rounded: false,
  }


  // margin-left: 10px;
  // margin-right: 10px;
  // margin-top: 20px;
  // margin-bottom: 10px;
  // padding-bottom: 20px;
  // width: 250px;

  const card_props = {
    width: "300px",
    height: "200px",
    margin: "20px 10px 10px 20px",
    radius: "10px"
  }

  const feed_style = {
        display: "flex",
        "flex-direction": "row",
        "flex-wrap": "wrap",
        "align-items": "flex-start",
        "justify-content": "center",
    }

  
  return (
    <div id="MenuViewPlaceholder">
      <Placeholder {...header_props}/>
      <div style={feed_style}>
        <Placeholder {...card_props}/>
        <Placeholder {...card_props}/>
        <Placeholder {...card_props}/>
        <Placeholder {...card_props}/>
        <Placeholder {...card_props}/>
        <Placeholder {...card_props}/>
        <Placeholder {...card_props}/>
        <Placeholder {...card_props}/>
        <Placeholder {...card_props}/>
      </div>
      
    </div>
  )
}
