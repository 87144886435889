import React from 'react';
import PropTypes from 'prop-types';

import { Card, CardAdd } from '../../organisms/Card/Card';

import './Project.scss';
import grenki from "../../static/images/grenki.jpeg";

import { fetchModule } from '../../fetch'


// export const Project = ({project_name, }) => {
//     // console.log("URL: ", window.location.pathname);
//     let project_id = window.location.pathname.replace("/project/", "");
//     console.log("project_id: ", project_id);
//     let 
    // projects_data = [
    //     {
    //         color: "red",
    //         title: "Целевая Аудитория",
    //         description: "Портрет аудитории, статистика и опросы",
    //         prefix_url: "/auditory/"
    //     },
    //     {
    //         color: "orange",
    //         title: "Эксперт",
    //         description: "Распаковка личности эксперта",
    //         prefix_url: "",
    //     },
    //     {
    //         color: "green",
    //         title: "Контент-план",
    //         description: "План историй для эксперта",
    //         prefix_url: "",
    //     },
    //     {
    //         color: "gray",
    //         title: "Команда",
    //         description: "Набор команды и задачи",
    //         prefix_url: "",
    //     },
    //     {
    //         color: "gray",
    //         title: "Паспорт проекта",
    //         description: "Документ описывающий цели проекта, отвественных и KPI",
    //         prefix_url: "",
    //     },
    // ]

export class Project extends React.Component {
        constructor() {
            super();
            this.state = {
                projects_metadata: {},
                projects_data: [
                    
                    {
                        color: "orange",
                        title: "Контент-план",
                        description: "Сюжетные линии контента для эксперта",
                        prefix_url: "/content_plan/",
                    },
                    {
                        color: "green", // "blue", //"red",
                        title: "Трекер задач",
                        // under_development: true,
                        description: "Задачи описанные по направлениям работ",
                        prefix_url: "/task_tracker/", //
                    },
                    {
                        color: "blue", // "blue", //"red",
                        title: "Целевая Аудитория",
                        // under_development: true,
                        description: "Портреты аудитории",
                        prefix_url: "/auditory/", //
                    },
                    // {
                    //     color:  "gray", //"green", //"orange",
                    //     title: "Эксперт",
                    //     under_development: true,
                    //     description: "Распаковка личности эксперта",
                    //     prefix_url: "/expert/", // ""
                    // },
                    

                    
                    // {
                    //     color: "gray",
                    //     title: "Команда",
                    //     description: "Набор команды и задачи. (Раздел в разработке)",
                    //     prefix_url: "",
                    // },
                    // {
                    //     color: "gray",
                    //     title: "Паспорт проекта",
                    //     description: "Документ описывающий цели проекта, отвественных и KPI. (Раздел в разработке)",
                    //     prefix_url: "",
                    // },
                ],
                project_id: ""
            }
        }
    
        componentDidMount = () => {
            
            const project_id = window.location.pathname.replace("/project/", "");
            console.log("project_id: ", project_id);
            this.getProjects(project_id);
        }
    
        getProjects = async (project_id) => {
            try {
              const response = await fetchModule.doGet({path: '/project/'+project_id});
              if ((response.status >= 200) && (response.status < 400)) {
                  let json = await response.json();
                  this.setState(state => ({
                    ...state, 
                    projects_metadata: json
                  }));  
                  return json;
              }
              else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                return json;
              } 
              else {
                  throw response.status; 
              }
            } catch (error) {
                throw error;
            }
        }
    
        render () {
            return(
                <div className="project">
                    <div className="project--text">
                        <h1>{this.state.projects_metadata.title ? this.state.projects_metadata.title : "Загрузка..."}</h1>
                    </div>
                    <div className="project--body">
                        {this.state.projects_data.map((card_data, index) => 
                            <Card 
                                card_type={"project"}
                                has_sub_menu={false}
                                has_id_in_url={false}
                                parent_prefix_url={"/project/" + this.state.projects_metadata.id}
                                card_prefix_url={card_data.prefix_url}
                                key={index.toString()}
                                id={index.toString()}
                                {...card_data}
                            />
                        )}
                    </div>
                    
                </div>
            );
        }
}
