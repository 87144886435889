import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { Card, CardAdd } from '../../organisms/Card/Card';

import { Modal } from '../../organisms/Modal/Modal';

import './ContentPlanList.scss';

import { fetchModule } from '../../fetch'



// export const ContentPlanList = () => {

export class ContentPlanList extends React.Component {
    constructor() {
        console.log("ContentPlanList");
        super();
        this.state = {
            project_id: -1,
            content_plans: [],
            content_plans_loaded: false
        }
        this.PROJECT_CREATE_MODAL_ID = "project_create_modal_id"
    }

    componentDidMount = () => {
        this.getContentPlans();
    }

    cardEditModal = (content_plan_id, title, description, value, time_length) => {
        console.log("cardEditModal___card_id: ", content_plan_id, title, description, value, time_length);
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = "projectslist projectslist--blurred"
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        const project_create_modal_fields = [
            {
                "form_key": "id",
                "name": "",
                "input_type": "hidden",
                "value": content_plan_id
            },
            {
                "form_key": "title",
                "name": "Название сюжета",
                "input_type": "text",
                "value": title
            },
            {
                "form_key": "description",
                "name": "Описание сюжета",
                "description": "Про что рассказываем в этом сюжете",
                "input_type": "textarea",
                "value": description
            },
            {
                "form_key": "value",
                "name": "Ценности",
                "description": "Какие ценности эксперта нужно выделить",
                "input_type": "textarea",
                "value": value
            },
            {
                "form_key": "time_length",
                "name": "Протяженность сюжетной линии",
                "description": "Например: постоянная, временная или короткая",
                "input_type": "text",
                "value": time_length
            }
        ]
        const project_create_modal = <Modal 
            id={this.PROJECT_CREATE_MODAL_ID} 
            modal_title={"Редактирование: " + title}
            fields={project_create_modal_fields}
            closeFunc={this.closeProjectCreationModal}
            onSubmit={this.onSubmitUpdateProject}
            submitButtonName={"Сохранить"}
        />
        modal_element.render(project_create_modal);
    }

    getContentPlans = async () => {
        const project_id = window.location.pathname.replace('project', '').replace('content_plan', '').replace(/\//g, '');
        console.log("_________project_id: ", project_id);
        this.setState(state => ({
            ...state, 
            project_id: project_id,
        }));
        try {
          const response = await fetchModule.doGet({path: '/project/' + project_id + "/content_plan/"});
          if ((response.status >= 200) && (response.status < 400)) {
              let json = await response.json();
              console.log("content_plans: ", json["content_plans"]);
              this.setState(state => ({
                ...state, 
                content_plans_loaded: true,
                content_plans: json["content_plans"]
              }));  
              return json;
          }
          else if ((response.status >= 400) && (response.status < 404)) {
            let json = await response.json();
            return json;
          } 
          else {
              throw response.status; 
          }
        } catch (error) {
            throw error;
        }
    }

    closeCardSettingsModal = (card_id) => {
        const element = document.getElementById(card_id + "__card_menu_modal")
        element.hidden = "True";
    }

    closeProjectCreationModal = (e) => {
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = ""
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        modal_element.render(<></>);
    }

    openProjectCreationModal = (e) => {
        const projectslist_element = document.getElementById("projectslist");
        projectslist_element.className = "projectslist projectslist--blurred"
        const modal_element = ReactDOM.createRoot(
            document.getElementById('card_add_modal_placement')
        );
        const project_create_modal_fields = [
            {
                "form_key": "title",
                "name": "Название сюжета",
                "input_type": "text"
            },
            {
                "form_key": "description",
                "name": "Описание сюжета",
                "description": "Про что рассказываем в этом сюжете",
                "input_type": "textarea"
            },
            {
                "form_key": "value",
                "name": "Ценности",
                "description": "Какие ценности эксперта нужно выделить",
                "input_type": "textarea"
            },
            {
                "form_key": "time_length",
                "name": "Протяженность сюжетной линии",
                "description": "Например: постоянная, временная или короткая",
                "input_type": "text"
            }
        ]
        const project_create_modal = <Modal 
            id={this.PROJECT_CREATE_MODAL_ID} 
            modal_title={"Создание сюжетной линии"}
            fields={project_create_modal_fields}
            closeFunc={this.closeProjectCreationModal}
            onSubmit={this.onSubmitNewProject}
            submitButtonName={"Создать сюжет"}
        />
        modal_element.render(project_create_modal);
    }

    onSubmitNewProject = async (e) => {
        console.log(e)
        const form_keys_array = ["title", "description", "value", "time_length"]
        const project_id = this.state.project_id;
        // for key_name in form_keys_array
        const project_data = {}
        form_keys_array.map((key_name, index) => {
                console.log("_________: ", this.PROJECT_CREATE_MODAL_ID + "_" + key_name);
                const element = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + key_name)
                console.log(key_name, ": ", element.value);
                project_data[key_name] = element.value;
            }
        )
        console.log("project_data: ", project_data);

        try {
            const response = await fetchModule.doPost({path: '/project/' + project_id + "/content_plan/", body: project_data});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                  ...state, 
                  content_plans: json["content_plans"]
                }));
                this.closeProjectCreationModal(null);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
    }

    onSubmitUpdateProject = async (e) => {
        console.log(e)
        const form_keys_array = ["title", "description", "value", "time_length"]
        // for key_name in form_keys_array
        const content_plan = {}
        form_keys_array.map((key_name, index) => {
                console.log("_________: ", this.PROJECT_CREATE_MODAL_ID + "_" + key_name);
                const element = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + key_name)
                console.log(key_name, ": ", element.value);
                content_plan[key_name] = element.value;
            }
        )
        const element_id = document.getElementById("modal_" + this.PROJECT_CREATE_MODAL_ID + "_" + "id")
        const content_plan_id = element_id.value;
        console.log("content_plan_id: ", content_plan_id);
        console.log("content_plan: ", content_plan);
        const project_id = this.state.project_id;

        try {
            const response = await fetchModule.doPut({path: '/project/' + project_id + "/content_plan/" + content_plan_id, body: content_plan});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                  ...state, 
                  content_plans: json["content_plans"]
                }));
                this.closeProjectCreationModal(null);
                this.closeCardSettingsModal(content_plan_id);
                return json;
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                this.closeCardSettingsModal(content_plan_id);
                return json;
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
        
    }

    render () {
        return(
            <div className="projectslist">
                <div id="card_add_modal_placement" className="projectslist--modal"></div>
                <div id="projectslist">
                    <div className="projectslist--text">
                        <h1>Контент план</h1>
                    </div>
                    <div className="projectslist--body">
                        {this.state.content_plans.map((card_data, index) => 
                            <Card card_type={"content_plan"} has_id_in_url={false} parent_prefix_url={"/project/" + this.state.project_id + "/content_plan/"} card_prefix_url={card_data.id + "/stories/"} key={index.toString()} {...card_data} cardEditModal={this.cardEditModal}  />
                        )}
                        {
                            ((this.state.content_plans.length == 0) && (this.state.content_plans_loaded == true))
                            ? <div className="projectslist--text">
                                <h2>Контент план ещё пуст</h2>
                                <p>Контент план состоит из сюжетов, в которых живут истории вашего эксперта</p>
                                <h3>Нажмите + чтобы создать новый сюжет</h3>
                                <CardAdd key={"add_card_key"} id={"add_card_id"} onClick={this.openProjectCreationModal}/>
                              </div>
                            : <CardAdd key={"add_card_key"} id={"add_card_id"} onClick={this.openProjectCreationModal}/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
