import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import { Card, CardAdd } from '../../organisms/Card/Card';

import { SharingAcceptModal } from '../../organisms/Sharing/Sharing'; 

import { Placeholder } from '../../atoms/Placeholder/Placeholder';

import './SharingAccept.scss';

import { fetchModule } from '../../fetch'
import { metric } from '../../metric'




// export const SharingAccept = () => {

export class SharingAccept extends React.Component {
    constructor() {
        super();
        this.state = {
            sharing_data: {},
            sharing_data_loaded: false,
            sharing_button_text: "Принять доступ",
            sharing_button_callback_func: this.acceptSharing,

        }
        this.PROJECT_CREATE_MODAL_ID = "project_create_modal_id"
    }

    componentDidMount = () => {
        metric.page_visit("sharing");
        console.log("sharing screen load");
        this.getSharingInfo();
    }

    getSharingInfo = async () => {
        const sharing_token = window.location.pathname.replace("/share/", ""); 
        try {
            const response = await fetchModule.doGet({path: '/share/' + sharing_token});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                    ...state, 
                    sharing_data_loaded: true,
                    sharing_data: json
                })); 
                return json
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                return json
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
    }


    acceptSharing = async () => {
        const sharing_token = window.location.pathname.replace("/share/", ""); 
        try {
            const response = await fetchModule.doPost({path: '/share/' + sharing_token});
            if ((response.status >= 200) && (response.status < 400)) {
                let json = await response.json();
                console.log("Response: ", json);
                this.setState(state => ({
                    ...state, 
                    sharing_data_loaded: true,
                    sharing_data: json,
                    sharing_button_text: "Открыть проекты",
                    sharing_button_callback_func: this.closeSharing
                })); 
                let modal_card_button = document.getElementsByClassName("card_button card_button--primary");
                console.log("modal_card_button: ", modal_card_button[0]);
                modal_card_button[0].className = "card_button card_button--green";
                return json
            }
            else if ((response.status >= 400) && (response.status < 404)) {
                let json = await response.json();
                return json
            } 
            else {
                throw response.status; 
            }
        } catch (error) {
            throw error;
        }
    }

    closeSharing = () => {
        window.open("/", "_self")
    }

    render () {
        const card_props = {
            width: "100%",
            height: "300px",
            margin: "20px 10px 10px 20px",
            radius: "10px"
          }
        return(
            <div className="projectslist">
                <div id="card_add_modal_placement" className="projectslist--modal"></div>
                <div id="projectslist">
                    <div className="projectslist--text">
                        <h1>Доступ к проекту</h1>
                    </div>
                    <br></br>
                    <div className="projectslist--body">
                        
                        {(this.state.sharing_data_loaded == true)
                        ? 
                            <SharingAcceptModal 
                                id={'accept_modal_id'}
                                modal_title={"Подтверждение доступа"}
                                fields={this.state.sharing_data}
                                onSubmit={this.state.sharing_button_callback_func}
                                closeFunc={this.closeSharing}
                                submitButtonName={this.state.sharing_button_text}
                            />
                        : <Placeholder {...card_props}/>
                        }
                        
                        {/* {this.state.projects_data.map((card_data, index) => 
                            {
                                return <Card 
                                            card_type={"project"}
                                            parent_prefix_url={"/"}
                                            card_prefix_url={"/project/"}
                                            key={index.toString()}
                                            cardEditModal={this.cardEditModal}
                                            has_share_in_sub_menu={true}
                                            cardShareModal={this.cardShareModal}
                                            {...card_data}
                                        />
                            }
                            
                        )}
                        {
                            ((this.state.projects_data.length == 0) && (this.state.projects_data_loaded == true))
                            ? <div>
                                <h2>Проекты ещё не созданы</h2>
                                <p>Проект – это рабочее пространство, где вы можете вести все, что связано с продюсированием вашего эксперта</p>
                                <h3>Нажмите + чтобы создать проект</h3>
                                <CardAdd key={"add_card_key"} id={"add_card_id"} onClick={this.openProjectCreationModal}/>
                              </div>
                            : <CardAdd key={"add_card_key"} id={"add_card_id"} onClick={this.openProjectCreationModal}/>
                        } */}
                        
                    </div>
                </div>
            </div>
        );
    }
}
